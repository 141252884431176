<template>
  <section :class="{ active: 1 }">
    <div
      v-if="!allowed"
      style="margin: 10px 20px;text-align: center; padding: 10px;
      border-radius: 5px;background-color: rgba(255, 0, 0, 0.5);color: white;
      display: flex;flex-direction: column;"
    >
      <h4
        style="margin: 12px auto 17px; font-size: 1.35rem; font-weight: 500;
         line-height: 1.2;"
      >
        Blocked
      </h4>
      <p style="margin-bottom: 1rem;">Обратитесь в службу поддержки ФК</p>
    </div>
    <div class="header-box">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12" v-html="description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

export default {
  name: "home",
  props: {
    allowed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      description: ""
    };
  },
  created() {
    let template = "{service.goodoc.description}";
    Axios.get(
      `${process.env.VUE_APP_JSTORAGE_URI}/texts/${template.replace(
        /[{}]*/gm,
        ""
      )}/ru`
    )
      .then(res => {
        if (template !== res.data) {
          this.description = res.data;
        }
      })
      .catch(() => {});
  }
};
</script>

<style scoped>
.card {
  border: unset !important;
}

.card-body {
  padding-top: 0 !important;
}

.header-box {
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
}

.header-box .header-box-h1,
h5 {
  color: #131420;
  font-size: 17px !important;
  margin-right: 17px;
  font-weight: 300 !important;
}

.header-box .header-box-h2 {
  font-size: 13px !important;
}

.header-box .header-box-h2 p {
  color: #92989b;
}

ol,
ul {
  list-style: disc !important;
  margin-left: 18px;
}

.p-header-p {
  margin-top: 16px;
}

p {
  margin-bottom: 4px !important;
}
</style>
