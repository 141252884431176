<template>
  <el-row type="flex" :gutter="6" justify="end" style="padding: 15px 0">
    <el-col
      :span="8"
      style="display: flex; justify-content: center; padding: 0 15px;
       flex-direction: column"
    >
      <el-image
        :src="row.thumbnailLink"
        :lazy="true"
        fit="contain"
        style="max-width: 150px; box-shadow: 1px 1px 3px 0;"
      >
        <image-placeholder slot="placeholder" />
        <image-placeholder slot="error" />
      </el-image>
    </el-col>
    <el-col :span="8">
      <div class="doc-details">
        <label :for="'createdTime' - row.id" v-text="'Создан:'" />
        <div v-text="row.createdTime" :id="'createdTime' - row.id" />
        <label :for="'modifiedTime' - row.id" v-text="'Изменен:'" />
        <div v-text="row.modifiedTime" :id="'modifiedTime' - row.id" />
      </div>
    </el-col>
    <el-col
      :span="8"
      :style="{
        'min-width': $slots.default.length && !row.deleted ? '158px' : '0'
      }"
    >
      <slot></slot>
    </el-col>
  </el-row>
</template>

<script>
import ImagePlaceholder from "../../snippets/image-placeholder";

export default {
  name: "docDetailsElement",
  components: { ImagePlaceholder },
  props: {
    row: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped></style>
