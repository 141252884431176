import Vue from "vue";
import _ from "lodash";
import amo from "./amo";
import api from "./api";
import * as helper from "./helper";
import Elements from "element-ui";
import ruLocale from "element-ui/lib/locale/lang/ru-RU";
import VueI18n from "vue-i18n";
import VueClipboard from "vue-clipboard2";

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

Vue.prototype.$_ = _;
Vue.prototype.$log =
  localStorage.getItem("goodoc_mode") !== "debug" ? () => {} : console.log;
Vue.prototype.$goodocEditLink = function(id) {
  return "https://docs.google.com/document/d/" + id + "/edit?usp=drivesdk";
};
Vue.prototype.$helper = helper;

if (typeof AMOCRM !== "undefined") {
  Vue.use(amo);
}
Vue.use(api);

Vue.use(VueI18n);
const messages = {
  ru: {
    title: "Русский",
    code: "ru",
    ...ruLocale
  }
};
const i18n = new VueI18n({
  locale: "ru",
  fallbackLocale: "ru",
  messages: messages
});

Vue.use(Elements, { i18n: (key, value) => i18n.t(key, value) });

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

export default Vue;
