<template>
  <el-image :src="url" fit="contain" />
</template>

<script>
export default {
  name: "image-placeholder",
  data() {
    return {
      url: window.goodoc.amo.widget().params.widget_domain + "/images/doc.svg"
    };
  }
};
</script>

<style scoped></style>
