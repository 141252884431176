<template>
  <!--    <section :class="{'active':0}">-->
  <section :class="{ active: 1 }">
    <component
      v-if="content"
      :is="require('./tab_content/' + content).default"
      :items="items"
      :config="config"
      v-model="value"
      @reload="$emit('reload')"
    />
  </section>
</template>

<script>
export default {
  name: "tab_content",
  props: {
    items: { type: Object, default: null },
    content: { type: String, default: "" },
    value: null,
    config: { type: Object, default: null }
  },
  data() {
    return {};
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    }
  }
};
</script>

<style scoped></style>
