<template>
  <div class="communications-template amoforms">
    <input
      type="radio"
      name="amoform-toggler"
      id="amoforms__editor"
      class="amoforms-tab-toggler js-form-changes-skip"
      checked="checked"
    />
    <div class="amoforms__tabs">
      <div class="amoforms__tab-editor">
        <div class="settings-analytics__title_stattistics-lead-complete">
          <div class="line-toggler ">
            <label class="line-toggler__item">
              <input
                type="radio"
                class="hidden"
                v-model="active_tab"
                value="1"
              />
              <span class="line-toggler__item-inner">Параметры</span>
            </label>
            <label class="line-toggler__item">
              <input
                type="radio"
                class="hidden"
                v-model="active_tab"
                value="2"
              />
              <span class="line-toggler__item-inner">Шаблон</span>
            </label>
          </div>
        </div>
        <div class="amoforms__tab-editor-left">
          <section v-show="active_tab == 1">
            <div>
              <amo-input
                v-for="(template, key) in inputs_template"
                :key="key"
                :title="template.title"
                :name="template.name"
                :input_class="'goodoc-max-width'"
                :value_pattern="template.value_pattern"
                v-model="value.appProperties[template.name]"
                @focusout.native="changeActiveElement"
                @focusin.native="changeActiveElement"
              />
            </div>
          </section>
          <section v-if="active_tab == 2">
            <div>
              <iframe
                :src="getGooDocEditLink(value)"
                height="1215"
                style="width: 100%"
              >
                Ваш браузер не поддерживает фреймы!
              </iframe>
            </div>
          </section>

          <div class="communications-template__bottom">
            <button
              type="button"
              :class="['button-input', saveBtnActualClass]"
              @click.prevent="callbacks.onSave(value)"
            >
              <span class="button-input-inner "
                ><span class="button-input-inner__text">Сохранить </span></span
              >
            </button>
            <button
              type="button"
              class="button-input button-cancel"
              @click.prevent="callbacks.onCancel"
            >
              <span>Отменить</span>
            </button>
          </div>
        </div>
        <div class="amoforms__tab-editor-right">
          <div class="amoforms__sidebar">
            <div
              v-for="(data, key) in $root.tools_data"
              class="amoforms__sidebar__wrapper"
              :key="key"
            >
              <div
                class="amoforms__sidebar__header"
                @click="toggleTemplatesSet(key)"
                v-text="key"
              ></div>
              <div
                :class="[
                  'amoforms__sidebar__items',
                  {
                    amoforms__sidebar__items_showed:
                      active_templates_set === key
                  }
                ]"
                :style="{
                  display: active_templates_set === key ? 'block' : 'none'
                }"
                @click="insertTemplate"
              >
                <div
                  v-for="(value, title) in data"
                  :key="title"
                  class="amoforms__sidebar__item
                  js-communications-template-marker"
                  :data-marker="getDataMarker(key, value)"
                  v-text="title"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <input
          id="goodoc-clipboard"
          class="_hidden"
          ref="clipboard"
          style="position: fixed; top:-2000px !important;
           left: -1000px !important; width: 200px!important;
            background-color: rgba(0,0,0,0); color: rgba(0,0,0,0)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import amoInput from "../../snippets/amo_input";

export default {
  name: "goodoc_template_edit",
  components: { amoInput },
  props: ["value", "callbacks"],
  data() {
    return {
      inputs_template: [
        { title: "Шаблон имени сохраняемого документа:", name: "nameMask" },
        {
          title: "Текущий № документа",
          name: "number",
          value_pattern: { f: /[^\d]+/g, r: "" }
        },
        { title: "Шаблон пути сохранения документа:", name: "pathMask" }
      ],
      input: "",
      old_value: null,
      active_tab: 1,
      active_templates_set: 0,
      active_element: {}
    };
  },
  created() {
    this.old_value = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    getGooDocEditLink(v) {
      return v.webViewLink;
    },
    toggleTemplatesSet(v) {
      if (this.active_templates_set === v) {
        this.active_templates_set = 0;
        return;
      }
      this.active_templates_set = v;
    },
    changeActiveElement() {
      let el = event.target;
      this.active_element = {
        el: el,
        selectionStart: el.selectionStart
      };
    },
    insertTemplate() {
      if (!event.target.getAttribute("data-marker")) {
        return;
      }
      if (this.active_element.el && this.active_tab == 1) {
        let el = this.active_element.el;
        let val = el.value;
        val = [
          val.slice(0, this.active_element.selectionStart),
          event.target.getAttribute("data-marker"),
          val.slice(this.active_element.selectionStart)
        ].join("");

        el.value = val;

        let ev = new Event("input");
        el.dispatchEvent(ev);

        el.selectionEnd = el.selectionStart =
          this.active_element.selectionStart +
          event.target.getAttribute("data-marker").length;
        el.focus();
      }

      this.copyToClipboard(event.target.getAttribute("data-marker"));
      if (this.active_element.el && this.active_tab === 1) {
        this.active_element.el.focus();
      }
    },
    copyToClipboard(v) {
      let old_active_element = document.activeElement;
      this.$refs.clipboard.value = v;
      // this.$refs.clipboard.classList.toggle('hidden');
      this.$refs.clipboard.focus();
      this.$refs.clipboard.select();
      document.execCommand("copy");
      // this.$refs.clipboard.classList.toggle('hidden');
      old_active_element.focus();
    },
    getDataMarker(entity, value) {
      if (entity === "Документ") {
        return `{{${value}}}`;
      }
      return `{{${entity}::${value}}}`;
    }
  },
  computed: {
    saveBtnActualClass() {
      let t = this.$_.isEqual(this.value, this.old_value);
      return t ? "button-input-disabled" : "button-input_blue";
    }
  }
};
</script>

<style scoped>
.modal-body.modal-body-relative {
  width: 80vw;
}

.modal-wp-amoforms .amoforms__tab-editor-left {
  width: calc(100% - 232px);
  margin-left: 5px;
  /*margin-right: 232px;*/
}

.settings-analytics__title_stattistics-lead-complete {
  left: 24px;
}

section {
  margin-top: 18px;
  margin-bottom: 10px;
}
</style>
