<template>
  <div class="single-doc-name" @click.capture="openInEventRibbon">
    <el-image
      :src="row.iconLink"
      fit="contain"
      v-if="row.iconLink"
      style="width: 16px; min-width: 16px; margin-right: 5px"
    />
    <span v-text="row.name" ref="title" />
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "docNameElement",
  props: {
    row: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    model: undefined
  }),
  methods: {
    openInEventRibbon() {
      if (this.row.deleted) {
        return;
      }
      try {
        if (this.row.appProperties.uploaded == "true") {
          window.open(this.row.webViewLink, "blank");
          return;
        }
      } catch (e) {}

      if (this.$refs.title.classList.contains("--goodoc-cur-opened--")) {
        this.modal.ribbonClose();
        return;
      }

      let _modal = require("../../snippets/amo_event_ribbon").default;
      let m_data = _modal.data();
      let link =
        this.$amo.user.goodoc_role > 1
          ? this.row.webViewLink
          : `https://docs.google.com/file/d/${this.row.id}/preview`;
      let child_component = {
        template:
          '<iframe src="' +
          link +
          "\" style='width: 100%; height: 100%'>" +
          "Ваш браузер не поддерживает фреймы!" +
          "</iframe>"
      };
      _modal.data = function() {
        return Object.assign(m_data, {
          child_component: child_component,
          modal_class: "doc-view",
          callbacks: {
            onCancel() {
              document
                .querySelectorAll(".--goodoc-cur-opened--")
                .forEach(v => v.classList.remove("--goodoc-cur-opened--"));
            }
          }
        });
      };

      this.modal = new Vue(_modal);
      document
        .querySelectorAll(".--goodoc-cur-opened--")
        .forEach(v => v.classList.remove("--goodoc-cur-opened--"));
      this.$refs.title.classList.add("--goodoc-cur-opened--");
      this.modal.$mount();
    }
  }
};
</script>

<style scoped lang="stylus">
.--goodoc-cur-opened--
    font-weight bold
</style>
