<template>
  <div class="widget_settings_block__item_field">
    <div
      class="widget_settings_block__title_field"
      :title="title"
      v-text="title"
      v-if="title"
    />
    <div class="">
      <input
        :name="name"
        :class="[
          'widget_settings_block__controls__ text-input',
          input_class || ''
        ]"
        ref="input"
        type="text"
        placeholder=""
        autocomplete="off"
        v-model="val"
        @goodocchange="changer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "amo_input",
  props: ["title", "name", "id", "input_class", "value", "value_pattern"],
  data() {
    return {
      val: this.value
    };
  },
  watch: {
    val() {
      this.applyPattern();
      this.$emit("input", this.val);
    },
    "this.$refs.input.value": {
      handler() {
        console.log(this.$refs.input.value);
      },
      deep: true
    }
  },
  methods: {
    applyPattern() {
      if (!this.value_pattern) {
        return;
      }
      this.val = this.val.replace(this.value_pattern.f, this.value_pattern.r);
    },
    changer() {
      this.$emit("input", this.val);
    }
  }
};
</script>

<style scoped></style>
