export function prepareCustomFields(res, customFields) {
  var tmp = {};
  tmp["Имя"] = res.name;
  tmp["id"] = res.id;
  if (typeof res.price !== "undefined" && res.price != 0) {
    tmp["Бюджет"] = res.price;
  }

  // eslint-disable-next-line no-unused-vars
  for (let [index, value] of Object.entries(res.custom_fields)) {
    // eslint-disable-next-line no-unused-vars
    for (let [i, v] of Object.entries(value.values)) {
      if (typeof value.code !== "undefined") {
        if (
          typeof customFields[value.code] !== "undefined" &&
          typeof customFields[value.code]["enums"] !== "undefined"
        ) {
          tmp[
            nameConvert(
              value.code,
              customFields[value.code]["enums"][v.enum],
              v.name
            )
          ] = v.value;
        } else {
          tmp[value.name] = value.values[0].value;
        }
      } else {
        tmp[value.name] = value.values[0].value;
      }
    }
  }

  return tmp;
}

export function nameConvert(code, name, defaultName) {
  let data = {
    EMAIL: {
      WORK: "Email раб.",
      PRIV: "Email личн.",
      OTHER: "Email др."
    },
    PHONE: {
      WORK: "Раб. тел.",
      WORKDD: "Раб.прямой",
      MOB: "Мобильный",
      FAX: "Факс",
      HOME: "Домашний",
      OTHER: "Другой"
    },
    IM: {
      SKYPE: "Skype",
      ICQ: "ICQ",
      JABBER: "Jabber",
      GTALK: "Google Talk",
      MSN: "MSN" //,
      //OTHER:  'Другой'
    }
  };
  if (
    typeof data[code] !== "undefined" &&
    typeof data[code][name] !== "undefined"
  ) {
    return data[code][name];
  }
  return defaultName;
}

export function createDataForEditorTool(name, custom_fields) {
  var data = {};
  data[name] = {};
  data[name]["Имя"] = "Имя";
  data[name]["Ответственный"] = "Отв-ный";
  if (name === "Сделка") {
    data[name]["Бюджет (число)"] = "Бюджет";
  }
  // eslint-disable-next-line no-undef
  $.each(custom_fields, function(i, v) {
    if (v.name === "{QUICK_DOC}") {
      return;
    }
    if (typeof v.enums === "undefined") {
      data[name][v.name] = v.name;
      return;
    }
    // eslint-disable-next-line no-undef
    $.each(v.enums, function(idx, val) {
      var tmp = nameConvert(v.code, val);
      data[name][v.name + " (" + tmp + ")"] = tmp;
    });
  });
  return data[name];
}

export function getETDocData() {
  return {
    "Имя шаблона": "ШаблонИмя",
    "Номер документа": "НомерДок",
    "Бюджет РУС(грн)": "budget::recipe::ru::uah",
    "Бюджет РУС(руб)": "budget::recipe::ru::rub",
    "Бюджет УКР(грн)": "budget::recipe::ua::uah",
    "Бюджет УКР(руб)": "budget::recipe::ua::rub",
    "Дата (d F Y)": "Дата::d F Y"
  };
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "application/pdf";
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function downloadURL(item, as, $api) {
  let data = {
    id: item.id,
    name: item.name,
    as: as,
    action: "download",
    amo: window.goodoc.amo.domain,
    key: $api._hash
  };
  data = btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  data = data.replace(/[=+/]/g, m => {
    return { "+": "-", "/": "_", "=": "," }[m];
  });
  return `${$api._host}/f/${data}`;
}

export function getFileLink(format, row) {
  if (row.appProperties.uploaded || !row.exportLinks) {
    return `<a href="${row.webContentLink}">${row.name}</a>`;
  }
  let url;
  switch (format) {
    case "PDF":
      url = row.exportLinks["application/pdf"];
      if (typeof url === "undefined") {
        return "";
      }
      return `<a href="${url}">${row.name.trim()}.pdf</a>`;
    case "DOCX":
      url =
        row.exportLinks[
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
      if (typeof url === "undefined") {
        return getFileLink("PDF", row);
      }
      return `<a href="${url}">${row.name.trim()}.docx</a>`;
    case "XLSX":
      url =
        row.exportLinks[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];
      if (typeof url === "undefined") {
        return getFileLink("PDF", row);
      }
      return `<a href="${url}">${row.name.trim()}.xlsx</a>`;
    case "PPTX":
      url =
        row.exportLinks[
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ];
      if (typeof url === "undefined") {
        return getFileLink("PDF", row);
      }
      return `<a href="${url}">${row.name.trim()}.pptx</a>`;
    default:
      return "";
  }
}

export class User {
  constructor(user_data, global_config) {
    Object.assign(this, user_data);
    if (global_config) {
      this.initRole(global_config);
    }
  }

  setConfig(config) {
    Object.defineProperty(this, "config", {
      value: config,
      configurable: true
    });
  }

  initRole(config) {
    this.setConfig(config);
    Object.defineProperty(this, "goodoc_role", {
      get() {
        try {
          let str_id = String(this.id);
          return this.config.signatories.signatories.includes(str_id)
            ? 3
            : this.config.signatories.writers.includes(str_id)
            ? 2
            : this.config.signatories.readers.includes(str_id)
            ? 1
            : 0;
        } catch (e) {
          return undefined;
        }
      },
      set(val) {
        let str_id = String(this.id);
        // eslint-disable-next-line max-len
        this.config.signatories.signatories = this.config.signatories.signatories.filter(
          v => v !== str_id
        );
        // eslint-disable-next-line max-len
        this.config.signatories.writers = this.config.signatories.writers.filter(
          v => v !== str_id
        );
        // eslint-disable-next-line max-len
        this.config.signatories.readers = this.config.signatories.readers.filter(
          v => v !== str_id
        );
        switch (val) {
          case 1:
            this.config.signatories.readers.push(str_id);
            break;
          case 2:
            this.config.signatories.writers.push(str_id);
            break;
          case 3:
            this.config.signatories.signatories.push(str_id);
            break;
        }
      },
      configurable: true
    });
  }
}
