import axios from "axios";

export default {
  name: "api",
  install(Vue) {
    try {
      Vue.prototype.$widget = window.goodoc.amo.widget();
    } catch (e) {
      Vue.prototype.$log("Catch exception in api.js: ", e.message);
    }
    Vue.prototype.$api = {
      _host: null,
      _hash: null,
      _inited: false,
      init() {
        if (this._inited) {
          return;
        }
        this._host ||
          (this._host = window.goodoc.amo.widget().params.widget_domain);
        this._hash ||
          (this._hash = window.goodoc.amo.widget().params.widget_api_key);
        axios.defaults.headers.common["Content-Type"] = "application/json";
        this._inited = true;
      },
      // eslint-disable-next-line no-unused-vars
      post(method, data = {}, ...args) {
        this.init();
        let url = null;
        data = {
          method: method,
          key: this._hash,
          data: Object.assign(data, {
            amo: window.goodoc.amo.domain,
            // eslint-disable-next-line no-undef
            amo_user: AMOCRM.constant("user")
          })
        };
        let post = axios.post(url || "{0}/api/v1/app".format(this._host), data);

        if (typeof arguments[arguments.length - 1] == "function") {
          return post.then(res => {
            arguments[arguments.length - 1](res.data.success);
          });
        }
        return post.then(res => {
          try {
            return Promise.resolve(res.data.success);
          } catch (e) {
            return Promise.reject(res);
          }
        });
      },
      getGooleToken() {
        return this.post("getAccessToken")
          .then(res => {
            return res.data || "";
          })
          .catch(() => {
            return "";
          });
      }
    };
    Vue.prototype.$amo_lead_notify = function(text) {
      // eslint-disable-next-line no-undef
      if (AMOCRM.data.current_entity !== "leads") {
        return;
      }
      // eslint-disable-next-line no-undef
      $.post("/api/v2/notes", {
        add: [
          {
            // eslint-disable-next-line no-undef
            element_id: AMOCRM.data.current_card.id,
            element_type: "2",
            note_type: "25",
            params: {
              text: text,
              // eslint-disable-next-line no-undef
              service: AMOCRM.constant("user").name
            }
          }
        ]
      });
    };
  }
};
