<template>
  <table class="content__account__settings">
    <tbody>
      <tr>
        <td
          class="content__account__settings__title"
          v-text="title"
          :style="title_width ? 'width:' + title_width : ''"
        ></td>
        <td class="content__account__settings__field">
          <div class="control--select-jstree">
            <js_tree
              :ref="'tree'"
              tag="ul"
              :data="tree"
              allow-batch
              whole-row
              @item-click="itemClick"
              v-if="tree.length"
              class="custom-scroll control--tree--list"
            >
              <template slot-scope="_">
                <div style="display: inherit; width: 200px">
                  <i
                    :class="_.vm.themeIconClasses"
                    role="presentation"
                    v-if="!_.model.loading"
                    :style="
                      _.model.iconLink
                        ? 'background-image: url(' +
                          _.model.iconLink +
                          ');background-position: 0;'
                        : ''
                    "
                  ></i>
                  <span>{{ _.model.text }}</span>
                </div>
              </template>
            </js_tree>
            <button
              class="control--select--button-tree"
              tabindex=""
              type="button"
              data-value="ru"
              @click.prevent="treeVisible(true)"
            >
              <span
                :ref="'button'"
                class="control--select--button-inner"
                v-text="buttonTextComputed"
                :key="buttonTextComputed"
              ></span>
            </button>
            <input
              v-model="value"
              :ref="'input'"
              type="hidden"
              class="control--select--input "
              id="time_format_id"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import js_tree from "vue-jstree";

export default {
  name: "jstree_select",
  props: ["tree_data", "title", "value", "title_width"],
  data() {
    return {
      tree: JSON.parse(JSON.stringify(this.tree_data)),
      buttonText: null
    };
  },
  created() {
    if (!this.value) {
      return;
    }
    this.prepareTreeData(this.tree);
  },
  components: { js_tree },
  methods: {
    itemClick(node, item) {
      this.$log("TYT");
      this.$refs.button.innerHTML = node.model.text;
      this.$refs.input.value = node.model.id;
      this.$emit("input", node.model.id);
      this.$emit("node_selected", item);
      this.treeVisible(false);
    },
    treeVisible(visible = false) {
      let cl;
      try {
        cl = this.$refs.tree.$el.classList;
      } catch (e) {
        return;
      }
      if (visible) {
        var e = this.$parent.$el.getElementsByClassName(
          "control--tree--list-opened"
        );
        for (var i = 0; i < e.length; i++) {
          e[i].parentElement.style = "";
          e[i].classList.add("control--tree--list");
          e[i].classList.remove("control--tree--list-opened");
        }
        cl.add("control--tree--list-opened");
        cl.remove("control--tree--list");
        this.$refs.tree.$el.parentElement.style.zIndex = 60;
        return;
      }

      cl.add("control--tree--list");
      cl.remove("control--tree--list-opened");
      this.$refs.tree.$el.parentElement.style = null;
    },
    prepareTreeData(node) {
      try {
        node.forEach((n, i) => {
          if (n.id === this.value) {
            this.buttonText = n.text;
            node[i].selected = true;
            throw new Error("found");
          }
          if ((node[i].opened = this.prepareTreeData(node[i].children))) {
            throw new Error("found");
          }
        });
      } catch (e) {
        if (e.message == "found") {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    buttonTextComputed() {
      try {
        if (this.buttonText) {
          return this.buttonText;
        }
        if (!this.tree[0].isfolder) {
          throw Error();
        }
        return this.buttonText || this.tree[0].text;
      } catch (e) {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.custom-scroll
    min-height 36px
</style>
