<!-- eslint-disable -->
<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size || 24"
    :height="props.size || 24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.364 8.87887L16.9497 7.46466L9.87868 14.5357L7.05025 11.7073L5.63604 13.1215L8.46447 15.9499L9.87868 17.3642L11.2929 15.9499L18.364 8.87887Z"
      :fill="props.color || '#0D8424'"
    />
  </svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: "svg-check"
};
</script>

<style scoped></style>
