<!-- eslint-disable -->
<template>
    <div class="tab-content">
        <div class="mdl-grid">
            <ul class="users-list-control mdl-list">
                <div class="mdl-grid">
                    <?php $users->each(function (&$user) { ?>
                    <li class="mdl-list__item mdl-cell mdl-cell--4-col">
                    <span class="mdl-list__item-primary-content">
                        <div class="avatar"></div>
                        <?=$user->name;?>
                    </span>
                        <span class="mdl-list__item-secondary-action" title="Может подписать" style="margin-right: 15px">
                        <label class="mdl-switch mdl-js-switch mdl-js-ripple-effect" for="worker_<?=$user->id;?>">
                            <input type="checkbox" name="signatories[]" id="worker_<?=$user->id;?>" value="<?=$user->id;?>" class="mdl-switch__input __serialize" />
                        </label>
                    </span>
                        <span class="mdl-list__item-secondary-action" title="Ответственный за подпись">
                        <label class="demo-list-radio mdl-radio mdl-js-radio mdl-js-ripple-effect" for="responsibleSigner_<?=$user->id;?>">
                            <input type="radio" id="responsibleSigner_<?=$user->id;?>" class="mdl-radio__button __serialize" name="responsibleSigner" value="<?=$user->id;?>" disabled />
                        </label>
                    </span>
                    </li>
                    <?php }); ?>
                </div>
            </ul>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                <input type="text" class="mdl-textfield__input" value="" id="task_type" readonly>
                <input value="" type="hidden" name="task_type" class="__serialize">
                <i class="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                <label class="mdl-textfield__label" for="task_type">Какую задачу ставить ответственному за подпись</label>
                <ul class="mdl-menu mdl-menu--bottom-left mdl-js-menu" for="task_type">
                    <?php $task_types->each(function ($type){ ?>
                    <li class="mdl-menu__item task_type" data-val="<?=$type->id;?>" {% if custom.task_type==type.id %} data-selected="true" {% endif %}><?=$type->name;?></li>
                    <?php }); ?>
                </ul>
            </div>
        </div>
    </div>
</template>
<!-- eslint-enable -->

<script>
    export default {
        name: "allways_hide",
        props: "value",
        data() {
            return {
                // eslint-disable-next-line vue/no-reserved-keys
                _value : this.value
            }
        }
    };
</script>

<style scoped>
    .avatar {
        background-image: url('/frontend/images/interface/i/userpic_demo.png');
    }
</style>
