<template>
  <el-row v-if="rows.length" type="flex" justify="space-between">
    <el-tooltip
      class="item"
      effect="light"
      content="Отменить выделение"
      placement="bottom-start"
    >
      <el-button
        type="text"
        class="clear-all"
        :size="btnSize"
        icon="el-icon-minus"
        @click="$emit('clear-selection')"
      />
    </el-tooltip>
    <div class="massactions">
      <el-button
        :size="btnSize"
        icon="el-icon-message"
        @click="handleAction('mail')"
        >Отправить на почту
      </el-button>
      <el-button
        v-if="$root.config.widget.show_insert_in_mail_btn"
        :size="btnSize"
        icon="el-icon-paperclip"
        @click="handleAction('insertInMail')"
        >Вставить в письмо
      </el-button>
    </div>
  </el-row>
  <el-row v-else type="flex" justify="end">
    <el-upload
      :show-file-list="false"
      :disabled="loading"
      v-show="showUpload"
      class="upload-demo"
      drag
      multiple
      :http-request="handleUpload"
      @submit="handleUpload"
      action="#"
    >
      <el-button
        :size="btnSize"
        type="primary"
        :loading="loading"
        style="padding: 6px 13px;"
        >Добавить файл</el-button
      >
      <span>Или перетащите файлы сюда</span>
    </el-upload>
  </el-row>
</template>

<script>
import axios from "axios";

export default {
  name: "massActions",
  props: {
    rows: Array,
    btnSize: {
      type: String,
      default: "mini"
    },
    config: Object
  },
  data() {
    return {
      loading: false,
      token: "",
      fileAppProperties: null
    };
  },
  methods: {
    getToken() {
      return this.$api.getGooleToken().then(res => {
        this.token = res;
        this.$log(res);
        return this.token;
      });
    },
    handleAction(action) {
      switch (action) {
        case "mail":
          this.sendMail();
          break;
        case "insertInMail":
          this.insertInMail();
          break;
        default:
          break;
      }
    },
    sendMail() {
      document.querySelector(".feed-compose-switcher").click();
      document.querySelector(".notes-wrapper__typing").click();
      let el = document.querySelector(".js-switcher-email:not(.hidden)");
      this.$log(el);
      if (!el) {
        return;
      }
      el.click();
      this.$amo.getDataForNewFile().then(data => {
        let LinkPDF = [],
          LinkDOCX = [],
          LinkXLSX = [],
          LinkPPTX = [],
          filename = [];
        this.rows.forEach(row => {
          LinkPDF.push("<p>" + this.$helper.getFileLink("PDF", row) + "</p>");
          LinkDOCX.push("<p>" + this.$helper.getFileLink("DOCX", row) + "</p>");
          LinkXLSX.push("<p>" + this.$helper.getFileLink("XLSX", row) + "</p>");
          LinkPPTX.push("<p>" + this.$helper.getFileLink("PPTX", row) + "</p>");
          filename.push(row.name);
        });

        data["Документ"] = {
          Имя: filename.join(", "),
          LinkPDF: LinkPDF.join(``),
          LinkDOCX: LinkDOCX.join(``),
          LinkXLSX: LinkXLSX.join(``),
          LinkPPTX: LinkPPTX.join(``)
        };
        this.$log(data);
        let self = this;
        let item = this.row;
        // eslint-disable-next-line no-undef
        $("body")
          .off("change", "#mail-template")
          .on("change", "#mail-template", function(e) {
            console.log("C_H_A_N_G_E", e.target.value, item);
            // eslint-disable-next-line no-undef
            if (!$(this).val()) {
              return;
            }
            setTimeout(function() {
              self.$api
                .post("getMailText", {
                  data: data,
                  timezone: self.$amo.timezone,
                  text: document
                    .querySelector("div.ql-editor")
                    .innerHTML.replace(
                      /<[^>]+>(\{{2}Документ::Link[^}]+\}{2})<\/[^>]+>/gm,
                      "$1"
                    )
                })
                .then(res => {
                  self.$log(res.data.text);
                  // eslint-disable-next-line no-undef
                  $("div.ql-editor")
                    .html(res.data.text)
                    .trigger("paste");
                });
            }, 500);
          });
        // this.$parent.$parent.hideAllDocsMenu();
      });
    },
    insertInMail() {
      let editor = window
        .getSelection()
        .anchorNode.parentNode.closest("div.ql-editor");
      if (!editor) {
        return;
      }

      let insert = [];
      this.rows.forEach(row => {
        insert.push(`<a href="${row.webViewLink}">${row.name}</a>`);
      });

      insert = insert.join(`\n`);
      window
        .getSelection()
        .anchorNode.parentNode.insertAdjacentHTML("beforeend", insert);

      let event = document.createEvent("HTMLEvents");
      event.initEvent("paste", true, true);
      event.eventName = "paste";
      editor.dispatchEvent(event);
    },

    handleUpload: async function(a) {
      this.loading = true;
      await this.getToken();
      let rndStr =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let appProps = await this.appProperties;
      let fr = new FileReader();
      fr.readAsDataURL(a.file);
      let data = await new Promise(resolve => {
        fr.onloadend = () => {
          let data = [
            "--" + rndStr,
            "content-type: application/json; charset=UTF-8",
            "",
            JSON.stringify({
              name: a.file.name,
              mimeType: a.file.type,
              parents: [this.config.folders.fuploads],
              appProperties: appProps,
              permissionIds: ["anyoneWithLink"]
            }),
            ""
          ].join(`\r\n`);
          data += [
            "--" + rndStr,
            "content-transfer-encoding: base64",
            "content-type: " + a.file.type,
            "",
            fr.result.split(",")[1],
            ""
          ].join(`\r\n`);
          data += "--" + rndStr + "--";
          resolve(data);
        };
      });

      await axios
        .post(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
          data,
          {
            headers: {
              "Content-Type": "multipart/related; boundary=" + rndStr,
              Authorization: `Bearer ${this.token}`
            },
            json: false
          }
        )
        .then(res => {
          this.$log(res);
          this.$notify({
            type: "success",
            message: `Файл загружен`,
            duration: 1000,
            showClose: false
          });
          this.$amo_lead_notify(`Файл "${res.data.name}" загружен`);
          return this.$api
            .post("setFilePermissions", { id: res.data.id })
            .catch(() => {})
            .then(() => {
              this.$emit("reload");
            });
        })
        .catch(() => {
          this.$log(...arguments);
          this.$notify({
            type: "error",
            dangerouslyUseHTMLString: true,
            message: `Во время загрузки файла<br>произошла ошибка`,
            duration: 3000,
            showClose: false
          });
        });

      this.loading = false;
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-async-in-computed-properties
    appProperties: async function() {
      if (!this.fileAppProperties) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        let data = await this.$amo.getDataForNewFile();
        let getId = key => {
          if (typeof data[key]["id"] === "undefined") {
            throw Error("");
          }
          return data[key]["id"] + "";
        };
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.fileAppProperties = Object.assign(
          {},
          (() => {
            try {
              return { leadid: getId("Сделка") };
            } catch (e) {
              return {};
            }
          })(),
          (() => {
            try {
              return { contactid: getId("Контакт") };
            } catch (e) {
              return {};
            }
          })(),
          (() => {
            try {
              return { companyid: getId("Компания") };
            } catch (e) {
              return {};
            }
          })(),
          { uploaded: true }
        );
      }
      return this.fileAppProperties;
    },
    showUpload() {
      try {
        return !!this.config.folders.fuploads;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.massactions
    display: flex
    flex-wrap: wrap
    justify-content: flex-end
    button
        margin: 2px
</style>
