<template>
  <div
    class="tab-content names-tab-content"
    v-if="items && value && folderId"
    v-show="treeData.length"
  >
    <templates-tree
      :config="config"
      :data.sync="treeData"
      icon-heigth="16px"
      :key="JSON.stringify($root.$children[0].old_value)"
    >
      <template v-slot:actions="{ data }">
        <el-button type="text" size="medium" @click="handleBtnAction(data)"
          >Настроить</el-button
        >
      </template>
    </templates-tree>
  </div>
</template>

<script>
import Vue from "vue";
import TemplatesTree from "../../snippets/templates-tree";

export default {
  name: "names",
  components: { TemplatesTree },
  props: ["value", "items", "config"],
  data() {
    return {
      oldAppProperties: null,
      treeData: []
    };
  },
  methods: {
    handleBtnAction(doc) {
      this.editClick(doc);
    },
    editClick(doc) {
      let _modal = require("../../snippets/amo_modal").default;
      let m_data = _modal.data();
      let self = this;
      this.oldAppProperties = Object.assign({}, doc.appProperties);
      _modal.data = function() {
        return Object.assign(m_data, {
          child_component: require("../modal_components/goodoc_template_edit")
            .default,
          transit_item: JSON.parse(JSON.stringify(doc)),
          tools_data: self.$root.$children[0].items.tools,
          modal_class: "goodoc-templates-edit",
          callbacks: {
            onSave(val) {
              self.$log(val);
              Object.assign(doc, val);
              self.saveToTemplates(doc);
              this.onCancel();
            },
            onCancel() {}
          }
        });
      };
      _modal.watch = Object.assign(_modal.watch || {}, {
        transit_item: {
          handler: function() {
            //console.log("changed transit_item");
            //console.log(doc);
            //this.transit_item.appProperties.number = this.transit_item.appProperties.number.replace("/[^\d]+/g", "");
            //console.log(this.transit_item.appProperties.number);
          },
          deep: true
        }
      });

      let modal = new Vue(_modal);
      modal.$mount();
    },
    saveToTemplates(doc) {
      this.$root.is_loading++;
      let appProperties = {};
      appProperties[doc.id] = doc.appProperties;
      this.$api.post(
        "changeAppProperties",
        { appProperties: appProperties },
        res => {
          console.log(res);
          res = res.data;
          if (res[doc.id].status) {
            doc.appProperties = res[doc.id].value;
          } else {
            doc.appProperties = Object.assign({}, this.oldAppProperties);
          }
          this.$root.is_loading--;
        }
      );
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    folderId() {
      try {
        return this.config.folders.ftemplates;
      } catch (e) {
        return;
      }
    }
  }
};
</script>

<style scoped></style>
