import { render, staticRenderFns } from "./folders.vue?vue&type=template&id=3aedf80c&scoped=true&"
import script from "./folders.vue?vue&type=script&lang=js&"
export * from "./folders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aedf80c",
  null
  
)

export default component.exports