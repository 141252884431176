var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"goodoc-tab-content"}},[(_vm.config && _vm.config.folders)?_c('div',{attrs:{"id":"lead_goodoc_tab_content_docs"}},[(_vm.$amo.user.goodoc_role > 1)?[_c('el-row',[_c('mass-actions',{attrs:{"rows":_vm.selected,"config":_vm.config},on:{"reload":_vm.getFilesFor,"clear-selection":_vm.handleClearSelections}})],1),_c('new-create-section-element',{attrs:{"config":_vm.config,"default-expand-all":!_vm.config.widget.hide_templates_tree},on:{"addedNew":_vm.getFilesFor}})]:_vm._e(),(_vm.showTable)?_c('el-table',{ref:"rootTable",attrs:{"data":_vm.tableData,"show-header":false,"default-expand-all":"","cell-class-name":"root-table","row-class-name":function (ref) {
        var row = ref.row;
        var index = ref.index;

        return (row.list.length ? 'table-headers' : 'hidden');
},"span-method":function() {
          return { rowspan: 1, colspan: 2 };
        }},on:{"row-click":function (row) {
          return; //TODO
          _vm.$refs.rootTable.toggleRowExpansion(row);
        }}},[_c('el-table-column',{attrs:{"prop":"name","align":"center"}}),_c('el-table-column',{attrs:{"align":"center","type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return (row.list.length)?_c('el-table',{ref:'sub:' + row.id,attrs:{"data":row.list,"show-header":false,"row-class-name":_vm.tableRowClassName,"row-key":function(r) {
              return r.id;
            },"span-method":function(ref) {
              var row = ref.row;
              var column = ref.column;
              var rowIndex = ref.rowIndex;
              var columnIndex = ref.columnIndex;

              return columnIndex === 2 ? { rowspan: 1, colspan: 2 } : [1, 1];
            }},on:{"select":_vm.handleSelect}},[_c('el-table-column',{attrs:{"type":"selection","width":"42"}}),_c('el-table-column',{attrs:{"prop":"name","class-name":"doc-name-col"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('doc-name-element',{attrs:{"row":scope.row}})}}],null,true)}),_c('el-table-column',{attrs:{"align":"right","width":"92","class-name":"action-cell"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('single-doc-actions-group',{attrs:{"row":scope.row,"quick":"","config":_vm.config,"signer":_vm.isSigner,"deleted":scope.row.deleted},on:{"update:deleted":function($event){return _vm.$set(scope.row, "deleted", $event)},"reload":_vm.getFilesFor,"expand":function (v) {
                  _vm.$refs['sub:' + row.id].toggleRowExpansion(scope.row, v);
                }}})}}],null,true)}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (true || (scope.row && scope.row.thumbnailLink))?_c('doc-details-element',{attrs:{"row":scope.row}},[_c('single-doc-actions-group',{staticStyle:{"min-width":"152px"},attrs:{"row":scope.row,"deleted":scope.row.deleted},on:{"update:deleted":function($event){return _vm.$set(scope.row, "deleted", $event)},"reload":_vm.getFilesFor,"expand":function (_) {}}})],1):_vm._e()}}],null,true)})],1):_vm._e()}}],null,true)})],1):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }