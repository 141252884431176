import axios from "axios";
import * as helper from "./helper";
import moment from "moment";

let user =
  typeof AMOCRM === "undefined"
    ? null
    : // eslint-disable-next-line no-undef
      new helper.User(AMOCRM.constant("user") || null);

export default {
  name: "amo",
  install(Vue) {
    Vue.prototype.$amo = {
      // eslint-disable-next-line no-undef
      AMOCRM: AMOCRM,
      get user() {
        return user;
        // return this.AMOCRM.constant("user") || null;
      },
      get isAdmin() {
        return this.AMOCRM.constant("user_rights").is_admin || false;
      },
      get card_id() {
        return this.AMOCRM.constant("card_id") || null;
      },
      get current_entity() {
        try {
          return this.AMOCRM.data.current_entity || null;
        } catch (e) {
          return null;
        }
      },
      get timezone() {
        return this.AMOCRM.system.timezone;
      },
      get current_card() {
        try {
          return this.AMOCRM.data.current_card || null;
        } catch (e) {
          return null;
        }
      },
      get loading() {
        return document
          .getElementsByTagName("body")[0]
          .classList.contains("page-loading");
      },
      set loading(val) {
        let body = document.getElementsByTagName("body")[0];
        if (val) {
          body.classList.add("page-loading");
        } else {
          body.classList.remove("page-loading");
        }
      },
      api: {
        _host: null,
        _hash: null,
        _inited: false,
        _init() {
          if (this._inited) {
            return;
          }
          this._host = "//" + window.location.host;
          axios.defaults.headers.common["Content-Type"] = "application/json";
          this._inited = true;
        },

        _get(url, query_data = {}) {
          this._init();
          return axios.get(this._host + url, query_data);
        },
        _post(url, data) {
          this._init();
          return axios.post(this._host + url, data);
        },
        _getEntityById(entity, id, key = null) {
          return this._get(`/private/api/v2/json/${entity}?id=${id}`).then(
            res => {
              return res.data.response[key || entity][0];
            }
          );
        },
        account() {
          return this._get("/private/api/v2/json/accounts/current/").then(
            res => {
              return Promise.resolve(res.data.response.account);
            }
          );
        },
        getLeadById(id) {
          return this._getEntityById("leads", id);
        },
        getContactById(id) {
          return this._getEntityById("contacts", id);
        },
        getCompanyById(id) {
          return this._getEntityById("company", id, "contacts");
        }
      },
      getDataForNewFile() {
        let data = { Сделка: {}, Контакт: {}, Компания: {} };
        let accountCustomFields = {};
        let users = {};
        return this.api
          .account()
          .then(account => {
            for (let [index, value] of Object.entries(account.custom_fields)) {
              accountCustomFields[index] = {};
              // eslint-disable-next-line no-unused-vars
              for (let [i, v] of Object.entries(value)) {
                accountCustomFields[index][v.code] = v;
              }
            }
            // eslint-disable-next-line no-unused-vars
            for (let [index, value] of Object.entries(account.users)) {
              users[value.id] = value;
            }
          })
          .then(() => {
            if (this.current_entity === "leads") {
              return this.api.getLeadById(this.current_card.id).then(res => {
                data.Сделка = helper.prepareCustomFields(
                  res,
                  accountCustomFields.leads
                );
                data.Сделка["Отв-ный"] = users[res.responsible_user_id].name;
                if (
                  typeof res.main_contact_id !== "undefined" &&
                  res.main_contact_id != 0
                ) {
                  data.Сделка.main_contact_id = res.main_contact_id;
                }
                if (
                  typeof res.linked_company_id !== "undefined" &&
                  res.linked_company_id != 0
                ) {
                  data.Сделка.linked_company_id = res.linked_company_id;
                }
              });
            }
          })
          .then(() => {
            if (
              this.current_entity === "contacts" ||
              (typeof data.Сделка.main_contact_id !== "undefined" &&
                data.Сделка.main_contact_id != 0)
            ) {
              return this.api
                .getContactById(
                  this.current_entity === "contacts"
                    ? this.current_card.id
                    : data.Сделка.main_contact_id
                )
                .then(res => {
                  data.Контакт = helper.prepareCustomFields(
                    res,
                    accountCustomFields.contacts
                  );
                  data.Контакт["Отв-ный"] = users[res.responsible_user_id].name;
                  if (
                    typeof res.linked_company_id !== "undefined" &&
                    res.linked_company_id != 0
                  ) {
                    data.Контакт.linked_company_id = res.linked_company_id;
                  }
                });
            }
          })
          .then(() => {
            if (
              this.current_entity === "companies" ||
              !!data.Сделка.linked_company_id ||
              !!data.Контакт.linked_company_id
            ) {
              return this.api
                .getCompanyById(
                  this.current_entity === "companies"
                    ? this.current_card.id
                    : this.current_entity === "leads"
                    ? data.Сделка.linked_company_id
                    : data.Контакт.linked_company_id
                )
                .then(res => {
                  data.Компания = helper.prepareCustomFields(
                    res,
                    accountCustomFields.companies
                  );
                  data.Компания["Отв-ный"] =
                    users[res.responsible_user_id].name;
                });
            }
          })
          .then(
            () => data,
            () => data
          );
      },
      setSigningTask(name, task_type, task_complete, responsibleSigner) {
        if (task_complete === null) {
          return;
        }
        let complete = this.calcTaskComplate(task_complete);
        return this.api._post("/private/api/v2/json/tasks/set/", {
          request: {
            tasks: {
              add: [
                {
                  element_id: this.current_card.id,
                  element_type: this.AMOCRM.element_types[this.current_entity],
                  complete_till: complete,
                  task_type: task_type,
                  text: name,
                  responsible_user_id: responsibleSigner,
                  created_by: this.current_card.user.id
                }
              ]
            }
          }
        });
      },
      /**
       * @param {String} task_comlate
       * @private
       */
      calcTaskComplate(task_comlate = "") {
        let t = moment();
        switch (task_comlate) {
          case "":
            break;
          default:
            task_comlate.split("|").forEach(template => {
              template = template.split(":");
              let args = template[1].split(",");
              t[template[0]](...args);
            });
        }
        return t.format("M/D/YYYY HH:mm");
      }
    };
  }
};
