<template>
  <div id="goodoc-tab-content">
    <div id="lead_goodoc_tab_content_docs" v-if="config && config.folders">
      <template v-if="$amo.user.goodoc_role > 1">
        <el-row>
          <mass-actions
            :rows="selected"
            @reload="getFilesFor"
            @clear-selection="handleClearSelections"
            :config="config"
          />
        </el-row>
        <new-create-section-element
          :config="config"
          @addedNew="getFilesFor"
          :default-expand-all="!config.widget.hide_templates_tree"
        />
      </template>
      <el-table
        :data="tableData"
        v-if="showTable"
        ref="rootTable"
        :show-header="false"
        default-expand-all
        cell-class-name="root-table"
        :row-class-name="
          ({ row, index }) => (row.list.length ? 'table-headers' : 'hidden')
        "
        @row-click="
          row => {
            return; //TODO
            $refs.rootTable.toggleRowExpansion(row);
          }
        "
        :span-method="
          function() {
            return { rowspan: 1, colspan: 2 };
          }
        "
      >
        <el-table-column prop="name" align="center" />
        <el-table-column align="center" type="expand">
          <el-table
            slot-scope="{ row }"
            v-if="row.list.length"
            :data="row.list"
            :show-header="false"
            :ref="'sub:' + row.id"
            :row-class-name="tableRowClassName"
            :row-key="
              function(r) {
                return r.id;
              }
            "
            :span-method="
              function({ row, column, rowIndex, columnIndex }) {
                return columnIndex === 2 ? { rowspan: 1, colspan: 2 } : [1, 1];
              }
            "
            @select="handleSelect"
          >
            <el-table-column type="selection" width="42" />
            <el-table-column prop="name" class-name="doc-name-col">
              <doc-name-element slot-scope="scope" :row="scope.row" />
            </el-table-column>
            <el-table-column align="right" width="92" class-name="action-cell">
              <single-doc-actions-group
                slot-scope="scope"
                :row="scope.row"
                quick
                :config="config"
                :signer="isSigner"
                :deleted.sync="scope.row.deleted"
                @reload="getFilesFor"
                @expand="
                  v => {
                    $refs['sub:' + row.id].toggleRowExpansion(scope.row, v);
                  }
                "
              />
            </el-table-column>
            <el-table-column type="expand">
              <doc-details-element
                slot-scope="scope"
                :row="scope.row"
                v-if="true || (scope.row && scope.row.thumbnailLink)"
              >
                <single-doc-actions-group
                  :row="scope.row"
                  style="min-width: 152px"
                  :deleted.sync="scope.row.deleted"
                  @reload="getFilesFor"
                  @expand="_ => {}"
                />
              </doc-details-element>
            </el-table-column>
          </el-table>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import SingleDocActionsGroup from "./elements/single-doc-actions-group";
import DocNameElement from "./elements/doc-name-element";
import DocDetailsElement from "./elements/doc-details-element";
import MassActions from "./elements/mass-actions";
import NewCreateSectionElement from "./elements/new-create-section-element";
import Axios from "axios";

export default {
  name: "entity-content",
  components: {
    NewCreateSectionElement,
    MassActions,
    DocDetailsElement,
    DocNameElement,
    SingleDocActionsGroup
  },
  data() {
    return {
      config: null,
      docs_lists: {
        forsigning: [],
        other: [],
        signed: []
      },
      tableData: [
        {
          id: "forsigning",
          name: "На подпись",
          list: []
        },
        {
          id: "other",
          name: "Документы",
          list: []
        },
        {
          id: "uploaded",
          name: "Прикрепленные файлы",
          list: []
        },
        {
          id: "signed",
          name: "Подписанные документы",
          list: []
        }
      ],
      selected: []
    };
  },
  created() {
    this.getConfig()
      .then(this.getFilesFor)
      .catch(v => {
        document.getElementById("goodoc-tab-content").innerHTML =
          `<div style="margin: 10px 20px;text-align: center; padding: 10px;border-radius: 5px;background-color: ${v.bg_color ||
            "rgba(255, 0, 0, 0.5)"};color: ${v.f_color ||
            "white"};display: flex;flex-direction: column;">` +
          (v.msg ||
            "Виджет не настроен" +
              (this.$amo.isAdmin
                ? `<br><br><a href="/settings/widgets/${this.$widget.params.widget_code}">Перейти в настройки</a>`
                : "")) +
          "</div>";
      });
  },
  methods: {
    getConfig() {
      return this.$api.post("getConfig").then(res => {
        if (!res.allowed) {
          let msg =
            '<h4 style="margin: 12px auto 17px; font-size: 1.35rem; font-weight: 500; line-height: 1.2;">Blocked</h4>' +
            '<p style="margin-bottom: 1rem;">Обратитесь в службу поддержки ФК</p>';
          return Promise.reject({ msg: msg });
        }
        this.$log(this.$amo.user);
        this.$amo.user.initRole(res.data);
        if (!this.$amo.user.goodoc_role) {
          return Axios.get(
            `${process.env.VUE_APP_JSTORAGE_URI}/texts/service.goodoc.user_access_denied/ru`
          ).then(res => {
            return Promise.reject({ msg: res.data, bg_color: "#203d49" });
          });
        }
        this.$root.config = this.config = res.data;
        return res;
      });
    },
    getFilesFor() {
      this.$log(...arguments);
      return this.$api
        .post("getFilesFor", {
          for: this.$amo.current_entity,
          cardid: this.$amo.card_id,
          folderid: this.config.folders.fdocs,
          timezone: this.$amo.AMOCRM.system.timezone
        })
        .then(res => {
          res = res.data;
          this.docs_lists = {
            signed: [],
            forsigning: [],
            other: [],
            uploaded: []
          };
          res.forEach(value => {
            if (value.appProperties.uploaded) {
              this.docs_lists.uploaded.push(value);
              return;
            }
            if (
              (value.appProperties.signed =
                value.appProperties.signed === "true")
            ) {
              value.appProperties.forsigning =
                value.appProperties.forsigning === "true";
              this.docs_lists.signed.push(value);
              return;
            }
            if (
              (value.appProperties.forsigning =
                value.appProperties.forsigning === "true")
            ) {
              this.docs_lists.forsigning.push(value);
              return;
            }
            this.docs_lists.other.push(value);
          });
          this.tableData.forEach(v => {
            v.list = this.docs_lists[v.id];
          });
        });
    },
    tableRowClassName({ row }) {
      return row.deleted ? ["doc-item-cell", "deleted"] : ["doc-item-cell"];
    },
    handleSelect() {
      this.selected = [];
      for (var i in this.$refs) {
        if (!i.startsWith("sub:")) {
          continue;
        }
        this.selected = this.selected.concat(this.$refs[i].selection);
      }
    },
    handleClearSelections() {
      Object.keys(this.$refs).forEach(v => {
        if (!v.startsWith("sub:")) {
          return;
        }
        this.$refs[v].clearSelection();
      });
      this.selected = [];
    }
  },
  computed: {
    isSigner() {
      try {
        return this.config.signatories.signatories.includes(
          this.$amo.user.id + ""
        );
      } catch (e) {
        return false;
      }
    },
    showTable() {
      return Object.keys(this.tableData).reduce((r, v) => {
        return r || this.tableData[v].list.length;
      }, false);
    }
  }
};
</script>

<style scoped lang="stylus"></style>
