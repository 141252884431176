<template>
  <div class="tab-content">
    <div class="G-config">
      <div class="left-side">
        <svg-google :size="32" />
        <div style="padding-left: 20px">
          <div>Google</div>
          <div style="font-size: 75%">Drive</div>
        </div>
      </div>
      <div class="right-side">
        <div
          v-if="items && items.user"
          style="display: flex;align-items: center"
        >
          <svg-check />
          <div
            style="display: flex; align-items: flex-start;
             flex-direction: column"
          >
            <span>{{ items.user.emailAddress }}</span>
            <div style="font-size: 75%">
              Подключил: {{ $root.config.account_linked_by }}
            </div>
          </div>
          <span class="link" @click="handleUnlink" style="margin-left: 20px"
            >Отсоединить</span
          >
        </div>
        <span v-else class="link" @click="handleLinkToGoogle"
          >Подключиться к Google</span
        >
      </div>
    </div>
    <div class="G-config t-doc-group" v-if="value">
      <template v-for="(v, key) in types_props">
        <el-checkbox
          :key="key"
          v-if="value.types"
          v-model="value.types[key]"
          v-bind="types_props[key]"
          border
        />
      </template>
    </div>
    <div class="G-config t-doc-group" v-if="value">
      <template v-for="(v, key) in widget_config_props">
        <el-checkbox
          :key="key"
          v-if="value.types"
          v-model="value[key]"
          v-bind="widget_config_props[key]"
          border
        />
      </template>
    </div>
  </div>
</template>

<script>
import SvgGoogle from "../../svg/svg-google";
import SvgCheck from "../../svg/svg-check";

export default {
  name: "widget",
  components: { SvgCheck, SvgGoogle },
  props: ["value", "items"],
  data() {
    return {
      types: [],
      types_props: {
        "application/vnd.google-apps.document": {
          label: "Google Docs",
          disabled: true,
          class: "t-doc"
        },
        "application/vnd.google-apps.spreadsheet": {
          label: "Google Sheets (alpha)"
        },
        "application/vnd.google-apps.presentation": {
          label: "Google Presentations (alpha)"
        }
      },
      widget_config_props: {
        show_insert_in_mail_btn: {
          label: 'Отображать кнопку "Вставить в письмо"'
        },
        hide_templates_tree: {
          label: "Сворачивать список шаблонов"
        }
      }
    };
  },
  methods: {
    handleLinkToGoogle() {
      window.removeEventListener("message", this.handleMessage);
      this.$api.post("setupServer").then(res => {
        res = res.data;
        let window_params =
          `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=600,` +
          `left=${((window.screen.availWidth - 500) / 2).toFixed(0)},top=10`;

        window.open(res.client.url, "test", window_params);
        window.addEventListener("message", this.handleMessage);
      });
    },
    handleMessage() {
      window.removeEventListener("message", this.handleMessage);
      this.$emit("reload");
    },
    handleUnlink() {
      this.$api.post("unlinkGoogle").then(() => {
        this.$emit("reload");
      });
    }
  }
};
</script>

<style scoped>
input[name="userfile"] {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 36px;
  width: 100%;
  z-index: 1;
}
</style>
