<!-- eslint-disable -->
<template>
  <section id="home-page">
    <div class="content w2 h1" @click="logoPos">
      <div class="logo">
        <svg
          version="1.1"
          id="_fk_"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          height="100%"
          viewBox="0 0 524 176.3"
          style="enable-background:new 0 0 524 176.3;"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M87.5,140.1H48.4c-5.6,0-5.4-11.9-2-61c3.3-49.1,2.8-57-2.7-57h39.1c5.6,0,6.1,7.9,2.7,57 C82.1,128.2,81.9,140.1,87.5,140.1z"
          />
          <path
            class="st0"
            d="M154.9,119.1h-39.1c-5.6,0-5.4-11.9-2-61c3.3-49.1,2.8-57-2.7-57h39.1c5.6,0,6.1,7.9,2.7,57 C149.5,107.2,149.3,119.1,154.9,119.1z"
          />
          <path
            class="st1"
            d="M243.4,24.4c12.6,0.7,17.1,4.8,17.2,16.2c-0.1,11.1-4.5,15.1-17.2,15.8v4.9H235v-4.9 c-12.6-0.7-17.1-4.8-17.1-15.8c0-11.4,4.5-15.6,17.1-16.2v-4.9h8.4V24.4z M235.4,30.7c-7.2,0.4-9.1,2.7-9.1,9.9 c0,6.8,1.9,9,9.1,9.5V30.7z M243,50.1c7.2-0.5,9.1-2.7,9.1-9.5c0-7.1-1.9-9.4-9.1-9.9V50.1z"
          />
          <path
            class="st1"
            d="M276.5,50l-2.7,9.9h-8.5l10.9-35.6c0.3-1.4,1.5-2.2,2.9-2.2h7.9c1.4,0,2.6,0.8,2.9,2.2l10.9,35.6h-8.5 l-2.7-9.9H276.5z M284.5,32c-0.3-1.2-0.6-2.4-0.8-3.6h-1.5c-0.3,1.2-0.5,2.4-0.8,3.6l-3.2,11.6h9.4L284.5,32z"
          />
          <path
            class="st1"
            d="M322.9,37.5c10.4,0,14,2.7,14,11.5c0,9.9-4.7,11.4-14,11.4c-4.9,0-9.2-0.1-13.9-0.5h-0.2V22.1l25.7,0l-0.7,6.4 h-16.7v8.9H322.9z M322.9,54c4.7,0,5.6-1,5.6-5.4c0-4.2-0.9-5.5-5.6-5.5h-5.8V54H322.9z"
          />
          <path
            class="st1"
            d="M345.7,22.1c4.7,0,7.6,0,14.4,0c10.4,0,13.7,2.9,13.7,11.8c0,9.8-3.3,12.4-12.7,12.4c-2.5,0-4.9-0.2-7.1-0.4 v14h-8.4V22.1z M359.9,39.9c4.3-0.1,5.6-1.3,5.6-6c0-4-1.3-5.4-5.6-5.4l-5.8,0.1v11.3H359.9z"
          />
          <path
            class="st1"
            d="M405.6,59.9V37.4c0-1.7,0.2-3.4,0.5-5.1h-0.4c-0.5,1.7-1.3,3.5-2.1,5l-12.8,22.7h-8.3V22.1h7.9v22.2 c0,1.7-0.2,3.5-0.4,5.3h0.3c0.6-1.8,1.4-3.3,2.2-4.9l12.8-22.5h8.3v37.8H405.6z"
          />
          <path
            class="st1"
            d="M441.4,40.3c7.5,1,8.4,5.4,13,19.6h-8.7c-3.9-12.8-4.8-16.7-10.7-16.7h-2.7c0.3,1.3,0.4,2.5,0.4,3.6v13h-8.3 V22.1h8.3v10.7c0,1.5-0.2,3-0.4,4.5h2.6c5.7,0,7-4.7,10-15.2h8.5c-3.9,12.3-4.8,17-12,18V40.3z"
          />
          <path
            class="st1"
            d="M472.4,50l-2.7,9.9h-8.5L472,24.3c0.3-1.4,1.5-2.2,2.9-2.2h7.9c1.4,0,2.6,0.8,2.9,2.2l10.9,35.6h-8.5l-2.7-9.9 H472.4z M480.3,32c-0.3-1.2-0.6-2.4-0.8-3.6h-1.5c-0.3,1.2-0.5,2.4-0.8,3.6l-3.2,11.6h9.4L480.3,32z"
          />
          <path
            class="st1"
            d="M237.1,99.4c7.5,1,8.4,5.4,13,19.6h-8.7c-3.9-12.8-4.8-16.7-10.7-16.7H228c0.3,1.3,0.4,2.5,0.4,3.6v13h-8.3 V81.2h8.3v10.7c0,1.5-0.2,3-0.4,4.5h2.6c5.7,0,7-4.7,10-15.2h8.5c-3.9,12.3-4.8,17-12,18V99.4z"
          />
          <path
            class="st1"
            d="M289.6,119h-8.4V87.2h-4.8c-2.2,0-3.3,1-3.5,3.2c-0.8,9.7-1.4,15.9-3.2,20.5c-2.4,6.6-6.6,8.9-12.5,8.2 l-0.6-6.5c2.6-0.5,4.4-1.4,5.4-4c1.5-3.9,2.1-8.8,3-19.3c0.4-5.4,3.4-8.2,8.9-8.2h15.8V119z"
          />
          <path
            class="st1"
            d="M323.4,119V96.4c0-1.7,0.2-3.4,0.5-5.1h-0.4c-0.5,1.7-1.3,3.5-2.1,5L308.6,119h-8.3V81.2h7.9v22.2 c0,1.7-0.2,3.5-0.4,5.3h0.3c0.6-1.8,1.4-3.3,2.2-4.9l12.8-22.5h8.3V119H323.4z"
          />
          <path
            class="st1"
            d="M367.7,119c-5.1,0-11.7,0-17.5,0c-5,0-8.3-2.8-8.3-7.5V88.7c0-4.7,3.4-7.4,8.3-7.4c5.9,0,12.4,0,17.5,0 l-0.3,6.6h-14.6c-1.8,0-2.6,0.8-2.6,2.8v5.8h15.1v6h-15.1v7.3c0,2,0.8,2.8,2.6,2.8h14.6L367.7,119z"
          />
          <path
            class="st1"
            d="M406.9,119h-8.4v-16.9h-13.3V119h-8.3V81.2h8.3v14.5h13.3V81.2h8.4V119z"
          />
          <path class="st1" d="M435,88v31h-8.4V88h-11.3v-6.8h31V88H435z" />
          <path
            class="st1"
            d="M485.7,100.1c0,15.2-4.3,19.6-16.2,19.6c-11.9,0-16.2-4.4-16.2-19.6s4.2-19.6,16.2-19.6 C481.4,80.5,485.7,84.9,485.7,100.1z M462.1,100.1c0,10,1.7,12.9,7.4,12.9c5.7,0,7.4-2.8,7.4-12.9c0-10-1.7-12.9-7.4-12.9 C463.8,87.2,462.1,90.1,462.1,100.1z"
          />
          <path
            class="st1"
            d="M516.2,99.5c5.8,0.5,7.8,4.1,7.8,10.1c0,7.8-3.9,9.4-12.8,9.4c-7.3,0-11.3,0-16,0V81.2c4.2,0,7.9,0,14.2,0 c9.9,0,13.3,2.4,13.3,9.4c0,5.4-1.9,8.2-6.6,8.7V99.5z M509.5,96.7c4,0,5.3-1,5.3-4.9c0-3.3-1.3-4.3-5.8-4.3h-5.6v9.2H509.5z M509.7,112.8c4.6,0,5.9-1.1,5.9-4.8c0-4.6-1.4-5.8-6.1-5.8h-6.2v10.6H509.7z"
          />
          <path
            class="st2"
            d="M48.4,140.2h39.1c7.2,0,15.4-17.1,31.6-69.6C135.2,18.1,143.5,1,150.6,1h-39.1C104.4,1,96.2,18.1,80,70.6 C63.8,123.1,55.6,140.2,48.4,140.2z"
          />
          <path
            class="st2"
            d="M115.8,119.1h39.1c5.6,0,9.2-9.8,21.6-50.1c12.4-40.3,15.5-46.8,21-46.8h-39.1c-5.6,0-8.6,6.5-21,46.8 C125,109.3,121.4,119.1,115.8,119.1z"
          />
          <path
            class="st2"
            d="M1,119.1h39.1c5.6,0,9.2-9.8,21.6-50.1c12.4-40.3,15.5-46.8,21-46.8H43.7c-5.6,0-8.6,6.5-21,46.8 C10.2,109.3,6.6,119.1,1,119.1z"
          />
          <path
            class="st1"
            d="M238.2,167.9v-3.1c-1.5,2.1-4,3.6-6.9,3.6c-5.4,0-9.2-4.1-9.2-10.8c0-6.6,3.8-10.8,9.2-10.8 c2.8,0,5.3,1.4,6.9,3.6v-11h3.2v28.4H238.2z M238.2,162.4V153c-1.2-1.8-3.6-3.3-6.2-3.3c-4.1,0-6.6,3.4-6.6,8 c0,4.6,2.5,7.9,6.6,7.9C234.6,165.6,237,164.1,238.2,162.4z"
          />
          <path
            class="st1"
            d="M248.5,142.3c0-1.2,1-2.2,2.1-2.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.1-2.2,2.1 C249.4,144.4,248.5,143.5,248.5,142.3z M249,167.9v-20.6h3.2v20.6H249z"
          />
          <path
            class="st1"
            d="M259.8,173.2l1.6-2.4c1.7,2,3.8,2.8,6.7,2.8c3.4,0,6.6-1.7,6.6-6.2v-2.9c-1.5,2.1-4,3.7-6.9,3.7 c-5.4,0-9.2-4-9.2-10.7c0-6.6,3.8-10.7,9.2-10.7c2.8,0,5.2,1.4,6.9,3.6v-3.1h3.2v20c0,6.8-4.8,9-9.8,9 C264.6,176.3,262.2,175.6,259.8,173.2z M274.7,162v-9c-1.1-1.8-3.6-3.3-6.1-3.3c-4.1,0-6.6,3.3-6.6,7.8c0,4.5,2.5,7.8,6.6,7.8 C271.1,165.4,273.6,163.8,274.7,162z"
          />
          <path
            class="st1"
            d="M284.9,142.3c0-1.2,1-2.2,2.1-2.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.1-2.2,2.1 C285.9,144.4,284.9,143.5,284.9,142.3z M285.5,167.9v-20.6h3.2v20.6H285.5z"
          />
          <path
            class="st1"
            d="M297.2,163.6v-13.5h-3.4v-2.8h3.4v-5.6h3.2v5.6h4.2v2.8h-4.2v12.8c0,1.5,0.7,2.6,2.1,2.6 c0.9,0,1.7-0.4,2.2-0.9l0.9,2.4c-0.8,0.8-2,1.3-3.8,1.3C298.7,168.4,297.2,166.7,297.2,163.6z"
          />
          <path
            class="st1"
            d="M323.4,167.9v-2.3c-1.7,1.9-4,2.9-6.8,2.9c-3.5,0-7.2-2.3-7.2-6.8c0-4.6,3.7-6.8,7.2-6.8 c2.8,0,5.2,0.9,6.8,2.8V154c0-2.8-2.2-4.3-5.2-4.3c-2.5,0-4.5,0.9-6.3,2.9l-1.5-2.2c2.2-2.3,4.9-3.4,8.2-3.4c4.3,0,8,2,8,6.9 v14.1H323.4z M323.4,163.6v-3.9c-1.2-1.7-3.4-2.6-5.7-2.6c-3,0-5.1,1.9-5.1,4.5c0,2.6,2.1,4.5,5.1,4.5 C320,166.1,322.2,165.3,323.4,163.6z"
          />
          <path class="st1" d="M334.2,167.9v-28.4h3.2v28.4H334.2z" />
          <path
            class="st1"
            d="M371.1,167.9v-2.3c-1.7,1.9-4,2.9-6.8,2.9c-3.5,0-7.2-2.3-7.2-6.8c0-4.6,3.7-6.8,7.2-6.8 c2.8,0,5.2,0.9,6.8,2.8V154c0-2.8-2.2-4.3-5.2-4.3c-2.5,0-4.5,0.9-6.3,2.9l-1.5-2.2c2.2-2.3,4.9-3.4,8.2-3.4c4.3,0,8,2,8,6.9 v14.1H371.1z M371.1,163.6v-3.9c-1.2-1.7-3.4-2.6-5.7-2.6c-3,0-5.1,1.9-5.1,4.5c0,2.6,2.1,4.5,5.1,4.5 C367.6,166.1,369.8,165.3,371.1,163.6z"
          />
          <path
            class="st1"
            d="M381.9,173.2l1.6-2.4c1.7,2,3.8,2.8,6.7,2.8c3.4,0,6.6-1.7,6.6-6.2v-2.9c-1.5,2.1-4,3.7-6.9,3.7 c-5.4,0-9.2-4-9.2-10.7c0-6.6,3.8-10.7,9.2-10.7c2.8,0,5.2,1.4,6.9,3.6v-3.1h3.2v20c0,6.8-4.8,9-9.8,9 C386.7,176.3,384.3,175.6,381.9,173.2z M396.8,162v-9c-1.1-1.8-3.6-3.3-6.1-3.3c-4.1,0-6.6,3.3-6.6,7.8c0,4.5,2.5,7.8,6.6,7.8 C393.2,165.4,395.7,163.8,396.8,162z"
          />
          <path
            class="st1"
            d="M406.5,157.6c0-6,4.3-10.8,10.1-10.8c6.2,0,9.9,4.9,9.9,11v0.8h-16.7c0.3,3.9,3,7.1,7.4,7.1 c2.3,0,4.7-0.9,6.4-2.6l1.5,2.1c-2,2-4.8,3.2-8.2,3.2C410.9,168.4,406.5,164.1,406.5,157.6z M416.6,149.5c-4.4,0-6.6,3.7-6.7,6.9 h13.5C423.3,153.3,421.3,149.5,416.6,149.5z"
          />
          <path
            class="st1"
            d="M446.8,167.9v-13.5c0-3.6-1.8-4.8-4.6-4.8c-2.5,0-4.9,1.5-6.1,3.2v15h-3.2v-20.6h3.2v3 c1.4-1.7,4.3-3.5,7.3-3.5c4.3,0,6.6,2.2,6.6,6.6v14.4H446.8z"
          />
          <path
            class="st1"
            d="M456.4,157.6c0-6.1,4.1-10.8,10.3-10.8c3.8,0,6,1.5,7.5,3.6l-2.1,2c-1.4-1.9-3.1-2.7-5.2-2.7 c-4.4,0-7.1,3.4-7.1,7.9c0,4.6,2.7,8,7.1,8c2.1,0,3.9-0.9,5.2-2.7l2.1,2c-1.6,2-3.8,3.6-7.5,3.6 C460.5,168.4,456.4,163.8,456.4,157.6z"
          />
          <path
            class="st1"
            d="M480.2,173.1c0.5,0.2,1.2,0.3,1.7,0.3c1.4,0,2.3-0.5,3.1-2.2l1.4-3.1l-8.6-20.8h3.5l6.9,16.9l6.8-16.9h3.5 L488,172.1c-1.2,3-3.3,4.1-6.1,4.2c-0.7,0-1.7-0.1-2.3-0.3L480.2,173.1z"
          />
        </svg>
      </div>
      <div class="title-wrapper">
        <img :src="logoSrc" />
        <div class="title" v-text="app_title" />
      </div>
    </div>
    <div class="g-description" v-html="description"></div>
    <footer class="footer">
      <div class="copyright">
        <a href="https://fabrika-klientov.ua/">2019 Fabrika Klientov</a>
      </div>
      <div class="privacy">
        <a href="/privacy.pdf">Политик конфиденциальности</a>
      </div>
    </footer>
  </section>
</template>
<!-- eslint-enable -->

<script>
import Axios from "axios";

export default {
  name: "home_page",
  data() {
    return {
      app_title: "GooDoc",
      description: ""
    };
  },
  created() {
    let template = "{service.goodoc.description}";
    Axios.get(
      `${process.env.VUE_APP_JSTORAGE_URI}/texts/${template.replace(
        /[{}]*/gm,
        ""
      )}/ru`
    ).then(res => {
      if (template !== res.data) {
        this.description = res.data;
      }
    });
  },
  methods: {
    logoPos(event) {
      let pos;
      if (
        event.clientX > (window.innerWidth * 0) / 3 &&
        event.clientX < (window.innerWidth * 1) / 3
      )
        pos = 1;
      if (
        event.clientX > (window.innerWidth * 1) / 3 &&
        event.clientX < (window.innerWidth / 3) * 2
      )
        pos = 2;
      if (
        event.clientX > (window.innerWidth / 3) * 2 &&
        event.clientX < (window.innerWidth / 3) * 3
      )
        pos = 3;
      let element = document.querySelector(".content");
      element.className = element.className.replace(/\bw\d\b/g, "w" + pos);

      pos = 1;
      element = document.querySelector(".content");
      element.className = element.className.replace(/\bh\d\b/g, "h" + pos);
    }
  },
  computed: {
    logoSrc() {
      return (
        process.env.VUE_APP_STATIC_HOST +
        process.env.VUE_APP_STATIC_PATH +
        "/widget/images/logo_small.png"
      );
    }
  }
};
</script>

<style scoped>
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-wrapper > img {
  margin-right: 20px;
  width: 108px;
  height: 108px;
  border-radius: 50%;
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.w1 {
  align-items: flex-start;
}

.w2 {
  align-items: center;
}

.w3 {
  align-items: flex-end;
}

.h1 {
  justify-content: flex-start;
}

.h2 {
  justify-content: center;
}

.h3 {
  justify-content: flex-end;
}

footer {
  padding: 0.3rem 0;
  width: 100%;
  font-size: 0.9rem;
}

footer .copyright {
  margin: 0 1rem;
}

footer .copyright a,
footer .copyright a:hover {
  text-decoration: none;
  color: #546e7a;
}

.title {
  font-size: 5rem;
  color: #0f3f4db5;
}

.logo {
  width: 30%;
  display: inline-flex;
  padding: 2rem;
}

.st0,
.st2 {
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke: #00bdf0;
}

.st0 {
  fill: none;
}

.st1 {
  fill: #ffffff;
}

.st2 {
  fill: #00bdf0;
}

.title {
  font-size: 5rem;
  color: #0f3f4db5;
}

.g-description {
  width: 60%;
  margin: 0 auto;
}

footer {
  display: flex;
  justify-content: space-between;
}

footer .privacy {
  margin: 0 1rem;
}

footer .privacy a,
footer .privacy a:hover {
  text-decoration: none;
  color: #546e7a;
}
</style>

<style>
#fk-goodoc-wrapper,
#home-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
