import Vue from "./features";
import Settings from "./settings/settings";
import Holder from "./pageholder";
import EntityContent from "./content/entity_content";
import "../../node_modules/element-ui/lib/theme-chalk/index.css";
import "../stylus/goodoc.styl";

window.moment = require("moment");

new Vue({
  name: "root",
  el: "#fk-goodoc-wrapper",
  components: { Holder, Settings, EntityContent },
  data() {
    return {
      is_loading: 0,
      config: {}
    };
  },
  beforeCreate() {
    let styles = document.getElementById("fakl--goodoc-styles");
    !styles || document.head.appendChild(styles);
    if (localStorage.getItem("goodoc_mode") === "debug") {
      window.app = this;
    }
  }
});
