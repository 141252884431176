<template>
  <div class="tab-content" v-if="items.jstreedata">
    <template v-for="e in elements">
      <component
        :is="require('../../snippets/jstree_select').default"
        v-if="items.jstreedata"
        :key="e.key"
        :title="e.title"
        :tree_data="items.jstreedata.nodes"
        v-model="value[e.key]"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "folders",
  props: ["value", "items"],
  data() {
    return {
      elements: [
        { key: "ftemplates", title: "Папка с шаблонами" },
        { key: "fdocs", title: "Папка готовых документов" },
        { key: "fsignatures", title: "Папка с подписями" },
        { key: "fuploads", title: "Папка для загрузки" }
      ]
    };
  }
};
</script>

<style scoped></style>
