<template>
  <el-table
    :data="tableData"
    :show-header="false"
    ref="templatesTable"
    v-show="config && treeData.length"
    :default-expand-all="!config.widget.hide_templates_tree"
    row-class-name="table-headers"
    :lazy="false"
    :span-method="
      function() {
        return { rowspan: 1, colspan: 2 };
      }
    "
    @row-click="
      row => {
        $refs.templatesTable.toggleRowExpansion(row);
      }
    "
  >
    <el-table-column prop="name" align="center" />
    <el-table-column type="expand">
      <templates-tree
        :config="config"
        :data.sync="treeData"
        @addedNew="$emit('addedNew', true)"
      />
    </el-table-column>
  </el-table>
</template>

<script>
import TemplatesTree from "../../snippets/templates-tree";
export default {
  name: "NewCreateSectionElement",
  components: { TemplatesTree },
  props: ["config"],
  data() {
    return {
      tableData: [{ name: "Создать документ из шаблона" }],
      treeData: []
    };
  }
};
</script>

<style scoped></style>
