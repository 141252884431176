<template>
  <div class="tab-content" v-if="items">
    <el-table :data="items.users" class="users-table" max-height="672">
      <el-table-column label="Пользователь" prop="name" />
      <el-table-column label="Права" fit>
        <template slot-scope="scope">
          <el-slider
            :max="3"
            show-stops
            v-model="scope.row.goodoc_role"
            :format-tooltip="
              function(v) {
                return user_roles[v];
              }
            "
          />
          <span v-text="user_roles[scope.row.goodoc_role]" />
        </template>
      </el-table-column>
    </el-table>
    <div
      style="margin-top: 20px; display: flex;justify-content: space-between"
      v-if="items.task_types"
    >
      <div v-text="'Какую задачу ставить ответственному за подпись'" />
      <el-select v-model="value.task_type" placeholder="Тип задачи">
        <el-option
          v-for="task in items.task_types"
          :key="`${task.id}-${task.name}`"
          :value="String(task.id)"
          :label="task.name"
        />
      </el-select>
      <el-select v-model="value.responsibleSigner" placeholder="Ответственный">
        <el-option
          v-for="user in signers"
          :key="user.id"
          :value="String(user.id)"
          :label="user.name"
        />
      </el-select>
      <el-select v-model="value.task_complete" placeholder="Срок выполнения">
        <el-option
          v-for="complete in task_completes"
          :key="complete.name"
          :value="complete.value"
          :label="complete.name"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "signatories",
  props: ["items", "value"],
  data: () => ({
    task_completes: [
      { name: "В момент создания", value: "" },
      { name: "Через 15 минут", value: "add:15,minutes" },
      { name: "Через 30 минут", value: "add:30,minutes" },
      { name: "Через 60 минут", value: "add:60,minutes" },
      { name: "До конца дня", value: "endOf:day" },
      { name: "Через день", value: "add:1,days|endOf:day" },
      { name: "Через 3 дня ", value: "add:3,days|endOf:day" }
    ],
    user_roles: {
      0: "Выключено",
      1: "Просмотр",
      2: "Редактирование",
      3: "Подпись"
    },
    users: []
  }),
  watch: {
    value: {
      handler() {
        // if (this.value.responsibleSigner && !this.value.signatories.includes(this.value.responsibleSigner)) {
        //     this.$el.querySelector("#r-" + this.value.responsibleSigner).checked = false;
        //     this.value.responsibleSigner = "";
        // }
        this.$emit("input", this.value);
      },
      deep: true
    }
  },
  methods: {
    handleChange(id, key) {
      let keys = ["readers", "signatories"].filter(v => v !== key);
      keys.forEach(v => {
        this.value[v] = this.value[v].filter(vv => vv !== id);
      });
      if (
        key === "signatories" &&
        !this.value.signatories.includes(this.value.responsibleSigner)
      ) {
        this.value.responsibleSigner = "";
      }
    }
  },
  computed: {
    signers() {
      try {
        return this.items.users.filter(v =>
          this.value.signatories.includes(String(v.id))
        );
      } catch (e) {
        return [];
      }
    }
  }
};
</script>

<style scoped>
/*.content-table__item__inner {
        justify-content: center;
    }

    .list__body__holder {
        overflow: visible;
    }
    .w_task-list {
        margin-top: 30px;
        align-items: center;
    }*/
</style>
