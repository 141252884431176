var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.treeData)?_c('el-tree',{staticClass:"templates-tree custom-scroll",attrs:{"node-key":"id","default-expand-all":false,"props":_vm.treeProps,"lazy":"","default-expanded-keys":[_vm.folderId],"load":_vm.loadNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-start"}},[(!data.isCommentForImbeciles)?_c('el-image',{style:({
          width: '16px',
          'min-width': '16px',
          'margin-right': '5px',
          height: _vm.iconHeigth
        }),attrs:{"src":data.iconLink,"fit":"contain"}}):_vm._e(),_c('span',{class:{ 'empty-tree-node--comment': data.isCommentForImbeciles },domProps:{"textContent":_vm._s(data.text)}})],1),(!data.isfolder && !data.isCommentForImbeciles)?_c('span',[_vm._t("actions",[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.createNewDoc(data)}}},[_vm._v("Создать")])],{"data":data})],2):_vm._e()])}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }