<template>
  <div
    :class="[
      'notes-wrapper loading-more scrolled --goodoc-remove--',
      modal_class
    ]"
  >
    <div class="notes-wrapper__scroller custom-scroll">
      <div class="notes-wrapper__scroller-inner">
        <div class="notes-wrapper__notes js-notes">
          <span class="modal-body__close" @click.prevent="ribbonClose"
            ><span class="icon icon-modal-close"></span
          ></span>
          <div class="modal-scroller custom-scroll">
            <component
              :is="child_component"
              v-model="transit_item"
              :callbacks="callbacks"
            />
            <div v-show="isResizing" class="--goodoc-resize-fixer--"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmoEventRibbon",
  data: () => ({
    child_component: null,
    transit_item: undefined,
    callbacks: undefined,
    modal_class: null,
    isResizing: false
  }),
  async beforeCreate() {
    let t = document.querySelector(
      ".card-holder__feed .--goodoc-remove-- span.modal-body__close"
    );
    !t || (await t.dispatchEvent(new Event("click")));
  },
  created() {
    this.resizer.addEventListener("mousedown", this.handleResizing);
    document.addEventListener("mouseup", this.handleResizing);
  },
  mounted() {
    if (!this.child_component) {
      this.ribbonClose();
      return;
    }
    this.holder
      .querySelector(".notes-wrapper")
      .classList.add("hidden", "--goodoc-restore--");
    this.holder.appendChild(this.$el);
  },
  methods: {
    ribbonClose() {
      this.resizer.removeEventListener("mousedown", this.handleResizing);
      document.removeEventListener("mouseup", this.handleResizing);

      this.holder
        .querySelector(".notes-wrapper.hidden.--goodoc-restore--")
        .classList.remove("hidden", "--goodoc-restore--");
      this.holder.removeChild(this.$el);
      try {
        this.callbacks.onCancel();
      } catch (e) {}
      this.$destroy();
    },
    handleResizing(event) {
      this.isResizing = event.type === "mousedown";
    }
  },
  watch: {
    "resizer.classList": {
      handler: function(v) {
        this.$log(v);
      },
      deep: true
    }
  },
  computed: {
    holder() {
      return document.querySelector(".card-holder__feed");
    },
    resizer() {
      return this.holder.parentNode.querySelector(
        ".js-card-column-resizer.card-holder__column-resizer"
      );
    }
  }
};
</script>

<style scoped lang="stylus">
.--goodoc-resize-fixer--
    position absolute
    top 0
    width 100%
    height 100%
    z-index 10
    opacity 0
.modal-body__close
    top 0
.modal-scroller
    &.custom-scroll
        margin-left 10px
        margin-top 34px
        background-color #FFFFFF
</style>
