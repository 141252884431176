<template>
  <el-button-group
    class="custom-button-group"
    v-if="quick && row.appProperties && row.appProperties.uploaded"
    v-show="!deleted"
  >
    <el-tooltip
      class="item"
      effect="light"
      content="Копировать ссылку в буфер"
      placement="bottom-start"
    >
      <el-button
        :size="btnSize"
        icon="el-icon-link"
        @click="handleAction('copy_link')"
      />
    </el-tooltip>
    <el-tooltip
      class="item"
      effect="light"
      content="Дополнительные"
      placement="bottom-end"
    >
      <el-button
        :size="btnSize"
        icon="el-icon-arrow-left"
        :class="['btn-expand', { expanded: expanded }]"
        @click="handleAction('expand')"
      />
    </el-tooltip>
  </el-button-group>
  <el-button-group class="custom-button-group" v-else-if="quick">
    <template v-if="$amo.user.goodoc_role > 1">
      <el-tooltip
        class="item"
        effect="light"
        content="Копировать ссылку в буфер"
        placement="bottom-start"
      >
        <el-button
          :size="btnSize"
          icon="el-icon-link"
          @click="handleAction('copy_link')"
        />
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="light"
        :content="signer ? 'Подписать' : 'Отправить на подпись'"
        placement="bottom"
        v-if="!row.appProperties.signed"
      >
        <el-button
          :size="btnSize"
          :icon="'el-icon-' + (signer ? 'finished' : 's-promotion')"
          @click="handleAction('sign')"
        />
      </el-tooltip>
    </template>
    <el-tooltip
      class="item"
      effect="light"
      content="Дополнительные"
      placement="bottom-end"
    >
      <el-button
        :size="btnSize"
        icon="el-icon-arrow-left"
        :class="['btn-expand', { expanded: expanded }]"
        @click="handleAction('expand')"
      />
    </el-tooltip>
  </el-button-group>
  <el-row
    v-else
    v-show="!deleted"
    class="custom-button-group-in-details"
    type="flex"
    justify="center"
  >
    <el-col
      style="display: flex; flex-direction: column; align-items: flex-end;
       padding-right: 3px"
    >
      <template v-if="row.appProperties && row.appProperties.uploaded">
        <el-button
          :size="btnSize"
          icon="el-icon-download"
          @click="download(null, row.webContentLink)"
          >Скачать</el-button
        >
        <template v-if="$amo.user.goodoc_role > 1">
          <el-button
            :size="btnSize"
            icon="el-icon-message"
            @click="handleAction('mail')"
            >Отправить на почту</el-button
          >
          <el-button
            :size="btnSize"
            icon="el-icon-paperclip"
            @click="handleAction('insertInMail')"
            v-if="$root.config.widget.show_insert_in_mail_btn"
            >Вставить в письмо</el-button
          >
          <el-button
            :size="btnSize"
            icon="el-icon-delete"
            @click="handleAction('delete')"
            >Удалить</el-button
          >
        </template>
      </template>
      <template v-else>
        <el-dropdown
          :size="btnSize"
          split-button
          class="dropdown-download"
          @click="download('application/pdf')"
          @command="download"
        >
          <i class="el-icon-download" />Скачать (PDF)
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(v, k) in downloadBtns"
              :key="k"
              :command="v"
              v-text="k"
            />
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          :size="btnSize"
          icon="el-icon-printer"
          @click="handleAction('print')"
          >Печать</el-button
        >
        <template v-if="$amo.user.goodoc_role > 1">
          <el-button
            :size="btnSize"
            icon="el-icon-message"
            @click="handleAction('mail')"
            >Отправить на почту
          </el-button>
          <el-button
            :size="btnSize"
            icon="el-icon-paperclip"
            @click="handleAction('insertInMail')"
            v-if="$root.config"
            v-show="$root.config.widget.show_insert_in_mail_btn"
            >Вставить в письмо
          </el-button>
          <el-button
            :size="btnSize"
            icon="el-icon-delete"
            @click="handleAction('delete')"
            :disabled="!!row.appProperties.signed"
            >Удалить
          </el-button>
        </template>
      </template>
    </el-col>
  </el-row>
</template>

<script>
import printJS from "print-js";

export default {
  name: "singleDocActionsGroup",
  props: {
    row: {
      type: Object,
      default: null
    },
    quick: {
      type: Boolean,
      default: false
    },
    signer: Boolean,
    config: Object,
    btnSize: {
      type: String,
      default: "mini"
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  watch: {
    config: {
      handler: function(o, v) {
        this.$log(`WATCH: ${this.isSigner}`, o, v);
      },
      deep: true
    }
  },
  methods: {
    handleAction(action) {
      switch (action) {
        case "expand":
          this.expand();
          break;
        case "copy_link":
          this.copyLink();
          break;
        case "sign":
          if (this.signer) {
            this.signit();
            break;
          }
          this.forsigning();
          break;
        case "mail":
          this.sendMail();
          break;
        case "insertInMail":
          this.insertInMail();
          break;
        case "print":
          this.print();
          break;
        case "delete":
          this.delete();
          break;
        default:
          break;
      }
    },
    expand() {
      this.expanded = !this.expanded;
      this.$emit("expand", this.expanded);
    },
    forsigning() {
      this.$log("forsigning");
      this.$amo.loading = true;
      this.$api
        .post("forsigning", { id: this.row.id })
        .then(res => {
          this.$log("res", res);
          this.$log("config", this.config);
          this.$amo_lead_notify(
            `Документ "${this.row.name}" отправлен на подпись`
          );
          this.$emit("reload");
          this.$amo
            .setSigningTask(
              this.row.name,
              this.config.signatories.task_type,
              this.config.signatories.task_complete,
              this.config.signatories.responsibleSigner
            )
            .then(res => {
              this.$log("fromAmo", res);
              this.$amo.loading = false;
            });
        })
        .catch(() => {
          this.$amo.loading = false;
        });
    },
    signit() {
      this.$log("signit");
      this.$amo.loading = true;
      this.$api
        .post("signit", { id: this.row.id })
        .then(res => {
          this.$log("res", res);
          this.$amo_lead_notify(`Документ "${this.row.name}" подписан`);
          this.$emit("reload");
          let t =
            res.data.id === "anyoneWithLink" && res.data.role === "reader";
          this.$notify({
            type: t ? "success" : "error",
            message: t
              ? "Документ подписан."
              : "Что-то пошло не так!!!<br>Документ не подписан.",
            dangerouslyUseHTMLString: true
          });
          this.$amo.loading = false;
        })
        .catch(() => {
          this.$amo.loading = false;
        });
    },
    sendMail() {
      document.querySelector(".feed-compose-switcher").click();
      document.querySelector(".notes-wrapper__typing").click();
      let el = document.querySelector(".js-switcher-email:not(.hidden)");
      this.$log(el);
      if (!el) {
        return;
      }
      el.click();
      this.$amo.getDataForNewFile().then(data => {
        data["Документ"] = {
          Имя: this.row.name,
          LinkPDF: this.$helper.getFileLink("PDF", this.row),
          LinkDOCX: this.$helper.getFileLink("DOCX", this.row),
          LinkXLSX: this.$helper.getFileLink("XLSX", this.row),
          LinkPPTX: this.$helper.getFileLink("PPTX", this.row)
        };
        this.$log(data);
        let self = this;
        let item = this.row;

        // eslint-disable-next-line no-undef
        $("body")
          .off("change", "#mail-template")
          .on("change", "#mail-template", function(e) {
            console.log("C_H_A_N_G_E", e.target.value, item);
            // eslint-disable-next-line no-undef
            if (!$(this).val()) {
              return;
            }
            setTimeout(function() {
              self.$api
                .post("getMailText", {
                  data: data,
                  timezone: self.$amo.timezone,
                  text: document.querySelector("div.ql-editor").innerHTML
                })
                .then(res => {
                  self.$log(res.data.text);
                  // eslint-disable-next-line no-undef
                  $("div.ql-editor")
                    .html(res.data.text)
                    .trigger("paste");
                });
            }, 500);
          });
      });
    },
    insertInMail() {
      let editor = window
        .getSelection()
        .anchorNode.parentNode.closest("div.ql-editor");
      if (!editor) {
        return;
      }
      let insert = `<a href="${this.row.webViewLink}">${this.row.name}</a>`;
      window
        .getSelection()
        .anchorNode.parentNode.insertAdjacentHTML("beforeend", insert);

      let event = document.createEvent("HTMLEvents");
      event.initEvent("paste", true, true);
      event.eventName = "paste";
      editor.dispatchEvent(event);
    },
    copyLink() {
      // this.$copyText(this.$goodocEditLink(this.row.id))
      this.$copyText(this.row.webViewLink)
        .then(() => {
          this.$notify.success({
            message: `Ссылка скопирована`,
            duration: 1000,
            showClose: false
          });
        })
        .catch(() => {
          this.$notify.error({
            message: `Ссылка не скопирована`,
            duration: 3000,
            showClose: false
          });
        });
    },
    print(url) {
      printJS(url || this.row.exportLinks["application/pdf"]);
    },
    delete() {
      this.$confirm(
        `Следующие документы будут удалены: <br>"${this.row.name}"`,
        `Удаление документов`,
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Продолжить",
          cancelButtonText: "Отменить",
          type: "warning"
        }
      )
        .then(() => {
          this.deleted = true;
          this.$api
            .post("delete", { id: this.row.id })
            .then(res => {
              this.$log(res);
              let t = this.row.appProperties.uploaded ? "Файл" : "Документ";
              this.$amo_lead_notify(`${t} "${this.row.name}" удален`);
              this.$notify({
                type: "success",
                message: `Документ "${this.row.name}" удален`
              });
              this.$emit("reload", true);
            })
            .catch(() => {
              this.$confirm(
                `Во время удаления произошла ошибка.`,
                `Удаление документов`,
                {
                  showConfirmButton: false,
                  cancelButtonText: "Закрыть",
                  type: "error"
                }
              );
              this.deleted = false;
            });
        })
        .catch(() => {});
    },
    download(as, url = "") {
      window.location.href = as ? this.row.exportLinks[as] : url;
    }
  },
  computed: {
    deleted: {
      get: function() {
        return !!this.row.deleted;
      },
      set: function(v) {
        this.$emit("update:deleted", v);
      }
    },
    downloadBtns() {
      let t = {
        DOCX:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        XLSX:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PPTX:
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        RTF: "application/rtf",
        CSV: "text/csv",
        TSV: "text/tab-separated-values",
        HTML: "text/html"
      };
      for (let i in t) {
        if (typeof this.row.exportLinks[t[i]] === "undefined") {
          delete t[i];
        }
      }
      return t;
    }
  }
};
</script>

<style lang="stylus"></style>
