<template>
  <div id="goodoc-settings">
    <div
      class="list__body-right__top"
      style="background-color: transparent; border-bottom-color: transparent"
    >
      <div
        class="list-top-search  list-top-search-responsive"
        id="search_input_wrapper"
        style="background-color: #f5f5f5; border-bottom-color: #f5f5f5  "
      >
        <button
          v-if="allowed"
          :class="[
            'button-input button-input_add save-button button-input_blue',
            saveBtnActualClass
          ]"
          @click.prevent="saveConfig"
        >
          СОХРАНИТЬ
        </button>
      </div>
    </div>
    <div
      class="default-overlay  left-menu-overlay default-overlay-visible
       hover-overlay hidden"
      id="left-menu-overlay"
      :ref="'overlay'"
    ></div>
    <div id="widget-body" v-show="ready">
      <el-tabs v-model="activeTabName" class="settings-tabs">
        <el-tab-pane :label="'Главная'.toUpperCase()" name="home">
          <home ref="home" :allowed="allowed" />
        </el-tab-pane>
        <el-tab-pane
          v-for="(item, key) in nav_menu.items"
          :label="item.title.toUpperCase()"
          :name="key"
          :disabled="!allowed"
          :key="key !== 'names' ? key : key + old_value_ftemplates"
        >
          <component
            :is="require('./tab_content').default"
            :ref="key"
            :content="key"
            :config="$root.config"
            :items="items[key !== 'names' ? key : 'folders']"
            v-model="value[key !== 'names' ? key : 'folders']"
            @reload="_created"
          />
        </el-tab-pane>
      </el-tabs>
      <!--            <home :ref="'home'" :allowed="allowed"/>-->
    </div>
    <div v-show="$root.is_loading" class="ivv-page-loading"></div>
  </div>
</template>

<script>
import _ from "lodash";
import tabContent from "./tab_content";
import Home from "./home";
import { Poppy } from "@shadoll/poppy";
import { User } from "../helper";

new Poppy().init();

export default {
  name: "settings",
  components: { Home, tabContent },
  data: () => {
    return {
      ready: false,
      nav_menu: {
        items: {
          signatories: { title: "Пользователи" },
          folders: { title: "Папки" },
          names: { title: "Шаблоны" },
          widget: { title: "Системные" }
        }
      },
      items: {
        signatories: {},
        folders: {},
        names: {},
        widget: null
      },
      allowed: false,
      value: {
        folders: {
          ftemplates: "",
          fdocs: "",
          fsignatures: "",
          fuploads: ""
        },
        signatories: {
          readers: [],
          writers: [],
          signatories: [],
          responsibleSigner: "",
          task_type: "",
          task_complete: null
        },
        names: null,
        widget: null
      },
      old_value: null,
      old_value_ftemplates: "",
      activeTabName: "home" //"signatories"//"names",//'home'
    };
  },
  created() {
    this._created();
  },
  watch: {
    value: {
      handler: function(v) {
        if (this.old_value_ftemplates === v.folders.ftemplates) {
          return;
        }
        if (v.folders.ftemplates) {
          this.renewDataForNamesTab();
        }
      },
      deep: true
    }
  },
  methods: {
    _created() {
      this.$root.is_loading++;
      this.$api
        .post("getConfig")
        .then(res => {
          if (res.allowed) {
            this.allowed = res.allowed;
          }
          res = res.data;
          if (!res) {
            return Promise.reject(res.message);
          }
          if (res.folders === null) {
            delete res.folders;
          }
          this.$root.config = Object.assign({}, res);
          this.$_.assignIn(this.value, this.$_.defaultsDeep(res, this.value));
          if (this.value.signatories.signatories) {
            this.value.signatories.signatories.sort();
          }
          this.old_value = JSON.parse(JSON.stringify(this.value));

          this.$root.is_loading++;
          this.$api
            .post("getTabsTemplateData")
            .then(res => {
              if (res.error) {
                return Promise.reject(res.message);
              }
              Object.assign(this.items, res.data);
              this.items.signatories.users = this.items.signatories.users.map(
                v => new User(v, this.$root.config)
              );
            })
            .catch(e => {
              if (
                [
                  "Trying to get property 'credentials' of non-object",
                  "invalid json token"
                ].includes(e)
              ) {
                this.activeTabName = "widget";
                this.items.widget = null;
              }
            })
            .then(() => {
              this.ready = true;
              this.$root.is_loading--;
            });

          this.$root.is_loading--;
        })
        .catch(() => {
          this.$root.is_loading--;
          this.ready = true;
        });
    },
    saveConfig() {
      this.$root.is_loading++;
      this.$api
        .post("saveConfig", Object.assign({}, this.value))
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          res = res.data;
          this.old_value = JSON.parse(JSON.stringify(this.value));
          this.$root.is_loading--;
        });
    },
    renewDataForNamesTab: _.debounce(function() {
      this.$root.is_loading++;
      this.$api
        .post("getTemplatesTree", {
          folderid: this.value.folders.ftemplates
        })
        .then(res => {
          res = res.data;
          this.items.folders.fl = res;
          this.old_value_ftemplates = this.value.folders.ftemplates;

          this.$root.is_loading--;
        })
        .catch(() => {
          this.$root.is_loading--;
        });
    }, 100)
  },
  computed: {
    saveBtnActualClass() {
      if (this.value.signatories.signatories) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value.signatories.signatories.sort();
      }
      let t = this.$_.isEqual(this.value, this.old_value);
      return t ? "hidden" : "";
    }
  }
};
</script>

<style scoped lang="stylus">
div.ivv-page-loading
    &:before
        content ""
        position fixed
        top 0
        right 0
        bottom 0
        left 330px
        background #fff
        z-index 100
        opacity .5
        @media (max-width 1100px)
            left 65px

    &:after
        content ""
        position fixed
        width 16px
        height 16px
        margin-top -8px
        margin-left -8px
        display block
        z-index 101
        border solid 2px transparent
        border-top-color #158fd2
        border-left-color #158fd2
        border-radius 100%
        -webkit-animation nprogress-spinner 900ms linear infinite
        animation nprogress-spinner 900ms linear infinite
        top 50%
        left calc(50vw + 165px)
        @media (max-width 1100px)
            left calc(50vw + 32px)
</style>
