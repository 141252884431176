<template>
  <el-tree
    node-key="id"
    class="templates-tree custom-scroll"
    :default-expand-all="false"
    :props="treeProps"
    lazy
    :default-expanded-keys="[folderId]"
    :load="loadNode"
    v-if="treeData"
  >
    <span class="custom-tree-node" slot-scope="{ node, data }">
      <span
        style="display: flex; align-items: center; justify-content: flex-start"
      >
        <el-image
          :src="data.iconLink"
          fit="contain"
          v-if="!data.isCommentForImbeciles"
          :style="{
            width: '16px',
            'min-width': '16px',
            'margin-right': '5px',
            height: iconHeigth
          }"
        />
        <span
          v-text="data.text"
          :class="{ 'empty-tree-node--comment': data.isCommentForImbeciles }"
        />
      </span>
      <span v-if="!data.isfolder && !data.isCommentForImbeciles">
        <slot name="actions" v-bind:data="data">
          <el-button type="text" size="mini" @click="createNewDoc(data)"
            >Создать</el-button
          >
        </slot>
      </span>
    </span>
  </el-tree>
</template>

<script>
import axios from "axios";

export default {
  name: "TemplatesTree",
  props: {
    config: Object,
    data: Array,
    iconHeigth: { type: String, default: "12px" }
  },
  data() {
    return {
      post_data: {
        action: "createNew",
        templateid: null,
        templatename: null,
        saveto: null,
        timezone: this.$amo.AMOCRM.system.timezone
      },
      treeProps: {
        label: "text",
        children: "children",
        isLeaf: "leaf"
      }
    };
  },
  created() {
    this.getTemplatesTree().then(res => {
      this.treeData = res;
    });
  },
  methods: {
    getTemplatesTree(id = null) {
      return this.$api
        .post("getTemplatesTree", {
          folderid: id || this.folderId
        })
        .then(res => {
          res = res.data;
          this.post_data.templateid = "";
          return this.prepareLeafs(res);
        });
    },
    prepareLeafs(list) {
      return list.map(v => {
        if (v.isfolder && v.children && v.children.length) {
          v.children = this.prepareLeafs(v.children);
        }
        v.leaf = !v.isfolder;
        return v;
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        if (this.treeData.length) {
          return resolve(this.treeData);
        } else {
          this.getTemplatesTree(this.folderId).then(res => {
            return resolve(res);
          });
        }
      } else {
        this.getTemplatesTree(node.data.id).then(res => {
          return resolve(res[0].children);
        });
      }
    },
    createNewDoc(data) {
      let id = data.id;
      if (data.mimeType !== "application/vnd.google-apps.document") {
        return this.copyForEntity(data);
      }
      let loadingInstance = this.$loading({
        target: this.$parent.$parent.$parent.$refs.templatesTable.$el,
        customClass: "goodoc-custom-loading"
      });
      this.$amo
        .getDataForNewFile()
        .then(data => {
          return this.$api.post(
            "createNew",
            Object.assign(
              { data: data },
              Object.assign({}, this.post_data, {
                templateid: id,
                saveto: this.saveToId
              })
            )
          );
        })
        .then(res => {
          this.$log(res);
          loadingInstance.close();
          if (res.error) {
            this.showModal(JSON.parse(res.message));
            return;
          }
          res = res.data;
          this.$amo_lead_notify(`Документ "${res.name}" создан`);
          setTimeout(() => {
            this.$emit("addedNew", true);
          }, 3e3);
          this.showModal(res);
        });
    },
    copyForEntity: async function(data) {
      let loadingInstance = this.$loading({
        target: this.$parent.$parent.$parent.$refs.templatesTable.$el,
        customClass: "goodoc-custom-loading"
      });
      let token = await this.$api.getGooleToken();

      let post_data = {
        name: data.text,
        mimeType: data.mimeType,
        parents: [this.config.folders.fdocs],
        appProperties: await this.appProperties,
        permissionIds: ["anyoneWithLink"]
      };
      await axios
        .post(
          `https://www.googleapis.com/drive/v3/files/${data.id}/copy`,
          post_data,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(res => {
          this.$notify({
            type: "success",
            message: `Документ скапирован`,
            duration: 1000,
            showClose: false
          });
          this.$amo_lead_notify(`Документ "${res.data.name}" скопирован`);
          return this.$api
            .post("setFilePermissions", { id: res.data.id })
            .catch(() => {})
            .then(() => {
              this.$emit("addedNew");
            });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            dangerouslyUseHTMLString: true,
            message: `Во время копирования файла<br>произошла ошибка`,
            duration: 3000,
            showClose: false
          });
        });

      loadingInstance.close();
    },
    showModal(res) {
      if (res.errorStatus === "pathMask") {
        return this.$confirm(
          "<span style='font-weight: bold'>Для выбранного Вами шаблона используется маска пути сохранения документа.</span><br><br>" +
            "Список не заполненных параметров:<br><ul>" +
            res.errorData.map(v => `<li>${v}</li>`).join("") +
            "</ul>",
          "Документ не создан!",
          {
            dangerouslyUseHTMLString: true,
            type: "error",
            showConfirmButton: false,
            cancelButtonText: "Закрыть"
          }
        )
          .then(() => {})
          .catch(() => {});
      } else {
        let msg = res.notreplaced
          ? "<span style='font-weight: bold'>Hо для следующих переменных не установлены значения:</span><br><br><ul>" +
            Object.values(res.notreplaced)
              .map(v => `<li>${v}</li>`)
              .join("") +
            "</ul>"
          : "<span style='font-weight: bold'>Все переменные успешно заполнены.</span>";
        return this.$confirm(msg, "Документ создан.", {
          dangerouslyUseHTMLString: true,
          type: res.notreplaced ? "warning" : "success",
          showConfirmButton: false,
          cancelButtonText: "Закрыть"
        })
          .then(() => {})
          .catch(() => {});
      }
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    folderId() {
      try {
        return this.config.folders.ftemplates;
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    saveToId() {
      try {
        return this.config.folders.fdocs;
      } catch (e) {
        return;
      }
    },
    treeData: {
      get: function() {
        return this.data;
      },
      set: function(v) {
        this.$emit("update:data", v);
      }
    },
    // eslint-disable-next-line vue/no-async-in-computed-properties
    appProperties: async function() {
      let fileAppProperties;

      // eslint-disable-next-line vue/no-async-in-computed-properties
      let data = await this.$amo.getDataForNewFile();
      let getId = key => {
        if (typeof data[key]["id"] === "undefined") {
          throw Error("");
        }
        return data[key]["id"] + "";
      };
      fileAppProperties = Object.assign(
        {},
        (() => {
          try {
            return { leadid: getId("Сделка") };
          } catch (e) {
            return {};
          }
        })(),
        (() => {
          try {
            return { contactid: getId("Контакт") };
          } catch (e) {
            return {};
          }
        })(),
        (() => {
          try {
            return { companyid: getId("Компания") };
          } catch (e) {
            return {};
          }
        })()
        //{ uploaded: true }
      );

      return fileAppProperties;
    }
  }
};
</script>

<style scoped></style>
