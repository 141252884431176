<template>
  <div
    class="modal modal-wp-amoforms modal-communications-template"
    id="vue-modal"
    v-if="child_component"
  >
    <div class="modal-scroller custom-scroll" @click.self.prevent="modalClose">
      <div :class="['modal-body modal-body-relative', modal_class]">
        <span class="modal-body__close" @click.prevent="modalClose"
          ><span class="icon icon-modal-close"></span
        ></span>
        <div class="modal-body__inner">
          <component
            :is="child_component"
            v-model="transit_item"
            :callbacks="callbacks"
          />
        </div>
      </div>
    </div>
    <div
      class="default-overlay modal-overlay modal-overlay_white
        default-overlay-visible"
    >
      <span
        class="modal-overlay__spinner spinner-icon spinner-icon-abs-center"
        style="display: none;"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "amo_modal",
  data() {
    return {
      child_component: null,
      transit_item: undefined,
      callbacks: undefined,
      modal_class: null
    };
  },
  created() {
    if (this.callbacks) {
      if (typeof this.callbacks.onCancel === "function") {
        let old_cancel = this.callbacks.onCancel;
        this.callbacks.onCancel = () => {
          old_cancel();
          this.modalClose();
        };
        return;
      }
      this.callbacks.onCancel = this.modalClose;
    }
  },
  mounted() {
    if (!this.child_component) {
      this.modalClose();
      return;
    }
    let body = document.querySelector("body");
    body.appendChild(this.$el);
    body.setAttribute(
      "data-body-fixed",
      body.getAttribute("data-body-fixed") * 1 + 1
    );
    body.style.overflow = "hidden";
  },
  methods: {
    modalClose() {
      this.$destroy();
      let body = this.$el.parentNode;
      body.removeChild(this.$el);
      body.setAttribute(
        "data-body-fixed",
        Math.max(0, body.getAttribute("data-body-fixed") * 1 - 1)
      );
      if (body.getAttribute("data-body-fixed") == 0) {
        body.style.overflow = null;
      }
    }
  }
};
</script>

<style scoped></style>
